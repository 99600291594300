import React, { useRef,useEffect } from "react";
import gsap from "gsap";
import "./Thermometer.css";

const Thermometer = (props) =>{
    const tempLine = useRef();
    const tempCircle = useRef();
    const tl = gsap.timeline({ repeat: -1 });

    const animate = () => {
    gsap.set(tempLine.current, {
        strokeDashoffset: "22px",
        strokeDasharray: "22px"
    });
    gsap.set(tempCircle.current, {
        scale: 0,
        transformOrigin: "center bottom"
    });

    tl.to(
        tempCircle.current,
        0.3,
        {
        scale: 1
        },
        "in"
    );
    tl.to(
        tempLine.current,
        1,
        {
        strokeDashoffset: "0px"
        },
        "in+=0.2"
    );
    };

    useEffect(() => {
        animate();
    },[])

    return (
        <svg
        version="1.1"
        className="Thermometer"
        ref={props.forwardedRef}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 100 100"
        xmlSpace="preserve"
        >
        <path
            id="therm"
            d="M50,67.997c-4.418,0-7.999-3.581-7.999-7.999c0-2.025,0.759-3.869,1.999-5.278V38.001
                c0-3.313,2.687-5.999,6-5.999c3.312,0,5.999,2.686,5.999,5.999V54.72c1.24,1.409,2,3.253,2,5.278
                C57.999,64.416,54.417,67.997,50,67.997z M51.999,56.553v-4.292V42v-3.999c0-1.104-0.896-2-1.999-2c-1.104,0-2,0.896-2,2V42v10.261
                v4.292c-1.19,0.693-2,1.969-2,3.445c0,2.209,1.791,3.999,4,3.999c2.208,0,3.999-1.79,3.999-3.999
                C53.999,58.521,53.189,57.246,51.999,56.553z"
        />
        <circle ref={tempCircle} cx="50.066" cy="59.96" r="2.934" />
        <line
            ref={tempLine}
            fill="none"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="50.029"
            y1="58.796"
            x2="50.029"
            y2="38.234"
        />
        </svg>
    );
}

export default Thermometer;