import React, { useRef, useEffect } from "react";
import gsap from "gsap";

import "./Clear.css";

const Clear = () => {
    const clear = useRef();

    const animate = () => {
        gsap.fromTo(
            clear.current,
            1,
            {
                rotation: 90,
                y: 30,
                opacity: 0
            },
            {
                y: 0,
                rotation: 0,
                opacity: 1,
                ease: 'back.out(1.7)'
            }
        );
    };

    useEffect(() => {
        animate();
    }, []);

    return (
        <svg
            version="1.1"
            className="Clear"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
        >
            <path
                ref={clear}
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeMiterlimit="10"
                d="M40.213,50.278c0.141,5.109,4.401,9.368,9.51,9.51
                C55.257,59.94,59.791,55.5,59.791,50c0,0-1.373-0.111-1.373-0.111c-4.387-0.354-7.883-3.814-8.283-8.198L50,40.209
                C44.5,40.209,40.06,44.743,40.213,50.278z"
            />
        </svg>
    );
};

export default Clear;