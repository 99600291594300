import React from "react";

import "./Main.css";

const main = React.forwardRef((props, ref) => {
    return (
        <main className="Main" ref={ref}>
            {props.children}
        </main>
    );
});

export default main;