import React, { useRef, useEffect } from "react";
import gsap from "gsap";

import "./PartlyCloudy.css";

const PartlyCloudy = () => {
    const sun = useRef();
    const cloud = useRef();
    const tl = gsap.timeline();

    const animate = () => {
        tl.fromTo(
            sun.current,
            1,
            {
                opacity: 0,
                y: 30
            },
            {
                transformOrigin: "center center",
                rotation: 90,
                opacity: 1,
                y: 0,
                ease: "back.out(1.7)"
            },
            "in"
        ).fromTo(
            cloud.current,
            1,
            {
                y: 30
            },
            {
                y: 0,
                ease: "back.out(1.7)"
            },
            "in"
        );
    };

    useEffect(() => {
        animate();
    }, []);

    return (
        <svg
            version="1.1"
            className="PartlyCloudy"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
        >
            <g ref={sun}>
                <circle
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeMiterlimit="10"
                    cx="53.074"
                    cy="49.94"
                    r="9.283"
                />
                <line
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="53.168"
                    y1="33.372"
                    x2="53.168"
                    y2="29.487"
                />
                <line
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="53.168"
                    y1="66.588"
                    x2="53.168"
                    y2="70.473"
                />
                <line
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="64.88"
                    y1="38.13"
                    x2="67.627"
                    y2="35.383"
                />
                <line
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="41.394"
                    y1="38.192"
                    x2="38.647"
                    y2="35.445"
                />
                <line
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="64.842"
                    y1="61.691"
                    x2="67.589"
                    y2="64.438"
                />
                <line
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="41.355"
                    y1="61.629"
                    x2="38.609"
                    y2="64.376"
                />
                <line
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="36.367"
                    y1="49.911"
                    x2="32.713"
                    y2="49.911"
                />
                <line
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    x1="69.814"
                    y1="49.911"
                    x2="73.469"
                    y2="49.911"
                />
            </g>
            <g ref={cloud}>
                <path
                    fill="#FFFDFD"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeMiterlimit="10"
                    d="M57.263,72.111
                    c5.628,0,11.329-4.592,11.329-10.219c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                    c0-3.789-3.728-9.805-12.605-9.805c-7.722,0-13.981,6.26-13.981,13.981s6.26,13.981,13.981,13.981s13.892-0.004,13.892-0.004
                    L57.263,72.111z"
                />
            </g>
        </svg>
    );
};

export default PartlyCloudy;