import React, { Component, Fragment } from "react";
import Media from "react-media";

import Weather from "./Components/Weather";
import Header from "./Components/UI/Header/Header";
import Main from "./Components/UI/Main/Main";
import Toggle from "./Components/UI/Toggle/Toggle";
import Footer from "./Components/UI/Footer/Footer";
import Search from "./Components/UI/Search/Search";
import Thermometer from "./Components/UI/Icons/Thermometer";

// Animated BG
import DayBG from "./Components/UI/Header/BG/animated/DayBG/DayBG";
import NightBG from "./Components/UI/Header/BG/animated/NightBG/NightBG";
// Static BG
import DayBGStatic from "./Components/UI/Header/BG/static/DayBG";
import NightBGStatic from "./Components/UI/Header/BG/static/NightBG";

import gsap from "gsap";

const foreCastURL = process.env.REACT_APP_URL;

class App extends Component {
    state = {
        weather: [],
        weatherCondition: [],
        weatherMeasurementUnit: "°C",
        windSpeedUnit: "KPH",
        locationValue: "",
        location: "",
        toggleClicked: false,
        dayNightCheck: null,
        errorMsg: null,
        isLoading: true,
        tempC: "",
        windC: "",
        feelsC: "",
        tempF: "",
        windF: "",
        feelsF: "",
        code: null
    };

    toggle = React.createRef();
    weatherCard = React.createRef();
    thermometer = React.createRef();

    locationValueUpdate = e => {
        this.setState({ locationValue: e.target.value });
    };

    submitLocationValue = e => {
        // Prevent default form submission
        e.preventDefault();
        // Prevent form submission if
        // location value is not present
        if (this.state.locationValue !== "") {
            if (this.state.isLoading) {
                // Show the loader if the request is taking longer than expected
                gsap.set(this.thermometer.current, { opacity: 1 });
            }
            const urls = [
                `${foreCastURL}query=${this.state.locationValue}&units=m`,
                `${foreCastURL}query=${this.state.locationValue}&units=f`
            ];
            Promise.all(
                urls.map(url => {
                    return fetch(url)
                        .then(response => {
                            if (!response.ok) {
                                throw response;
                            }
                            return response.json();
                        })
                        .then(data => {
                            console.log(data);
                            console.log(data.current.weather_code)
                            url.includes("units=m")
                                ? this.setState({
                                      isLoading: false,
                                      weather: data.current,
                                      weatherCondition:
                                          data.current.weather_descriptions[0],
                                      location: data.location.name,
                                      dayNightCheck: data.current.is_day,
                                      tempC: data.current.temperature,
                                      windC: data.current.wind_speed,
                                      feelsC: data.current.feelslike,
                                      code : data.current.weather_code,
                                      errorMsg: null
                                  })
                                : this.setState({
                                      tempF: data.current.temperature,
                                      windF: data.current.wind_speed,
                                      feelsF: data.current.feelslike
                                  });
                            // Showing the weather card on form submit if loading has finished
                            if (!this.state.isLoading) {
                                gsap.to(this.weatherCard.current, 1, {
                                    opacity: 1,
                                    ease: "expo.out"
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({
                                errorMsg:
                                    "Something went wrong, please try again.",
                                isLoading: false
                            });
                        });
                })
            );
        }
    };

    toggleWeatherUnit = () => {
        this.state.weatherMeasurementUnit === "°C"
            ? this.setState({
                  weatherMeasurementUnit: "°F",
                  windSpeedUnit: "MPH",
                  toggleClicked: !this.state.toggleClicked
              })
            : this.setState({
                  weatherMeasurementUnit: "°C",
                  windSpeedUnit: "KPH",
                  toggleClicked: !this.state.toggleClicked
              });

        this.state.toggleClicked
            ? gsap.to(this.toggle.current, 0.2, {
                  x: "0%",
                  ease: "expo.out"
              })
            : gsap.to(this.toggle.current, 0.2, {
                  x: "100%",
                  ease: "expo.out"
              });
    };

    render() {
        return (
            <Fragment>
                <Header>
                    <Search
                        onchange={this.locationValueUpdate}
                        onclick={this.submitLocationValue}
                    >
                        <Media query="(min-width:768px)">
                            {matches =>
                                matches ? (
                                    this.state.dayNightCheck === "no" ? (
                                        <NightBG />
                                    ) : (
                                        <DayBG />
                                    )
                                ) : this.state.dayNightCheck === "no" ? (
                                    <NightBGStatic />
                                ) : (
                                    <DayBGStatic />
                                )
                            }
                        </Media>
                    </Search>
                </Header>

                <Main>
                    {!this.state.isLoading && !this.state.errorMsg ? (
                        this.state.weatherMeasurementUnit === "°C" ? (
                            <Weather
                                location={this.state.location}
                                temperature={this.state.tempC}
                                temperatureFeel={this.state.feelsC}
                                condition={this.state.weatherCondition}
                                icon={this.state.weather.weather_icons}
                                unit={this.state.weatherMeasurementUnit}
                                wind={this.state.weather.wind_dir}
                                windSpeed={this.state.windC}
                                speedUnit={this.state.windSpeedUnit}
                                isDay={this.state.dayNightCheck}
                                forwardRef={this.weatherCard}
                                code={this.state.code}
                            >
                                <Toggle
                                    clicked={this.toggleWeatherUnit}
                                    ref={this.toggle}
                                />
                            </Weather>
                        ) : (
                            <Weather
                                location={this.state.location}
                                temperature={this.state.tempF}
                                temperatureFeel={this.state.feelsF}
                                condition={this.state.weatherCondition}
                                icon={this.state.weather.weather_icons}
                                unit={this.state.weatherMeasurementUnit}
                                wind={this.state.weather.wind_dir}
                                windSpeed={this.state.windF}
                                speedUnit={this.state.windSpeedUnit}
                                isDay={this.state.dayNightCheck}
                                forwardRef={this.weatherCard}
                                code={this.state.code}
                            >
                                <Toggle
                                    clicked={this.toggleWeatherUnit}
                                    ref={this.toggle}
                                />
                            </Weather>
                        )
                    ) : (
                        <div>
                            <h1>{this.state.errorMsg}</h1>
                        </div>
                    )}
                    {this.state.isLoading ? (
                        <Thermometer forwardedRef={this.thermometer} />
                    ) : null}
                </Main>
                <Footer />
            </Fragment>
        );
    }
}

export default App;