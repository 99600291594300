import React from "react";

import "./Toggle.css";

const toggle = React.forwardRef((props, ref) => {
    return (
        <div className="Toggle" onClick={props.clicked}>
            <p className="Circle" ref={ref}></p>
        </div>
    );
});

export default toggle;