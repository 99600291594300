import React, { Component } from "react";
import gsap from "gsap";

import "./Rain.css";

// Making an array for all the rain line svg els
const rainLines = [
    { name: "line1", x1: "41.5", y1: "52.876", x2: "41.5", y2: "66.124" },
    { name: "line2", x1: "49.21", y1: "55.876", x2: "49.21", y2: "69.124" },
    { name: "line3", x1: "57.211", y1: "52.876", x2: "57.211", y2: "66.124" }
];
// Styling the lines
const styles = {
    strokeDashoffset: "40px",
    strokeDasharray: "40px"
};

class Rain extends Component {
    rain = React.createRef();
    cloud = React.createRef();
    tl = gsap.timeline({
        paused: true
    });
    rainTl = gsap.timeline({
        paused: true
    });
    masterTl = gsap.timeline();

    animate = () => {
        // SET
        gsap.set(this.cloud.current, {
            y: 30
        });
        // ANIMATE
        this.tl.to(
            this.cloud.current,
            1,
            {
                y: 0,
                ease: 'back.out(1, 7)'
            },
            "in"
        );
        // LOOP OVER LINES & ANIMATE
        rainLines.forEach((e, i) => {
            this.rainTl.to(
                this.refs[e.name],
                1,
                {
                    "stroke-dashoffset": "0px",
                    "stroke-dasharray": "40px",
                    repeat: -1,
                    ease: 'expo.in'
                },
                0.1 * i
            );
        });

        this.masterTl.add(this.tl.play(), 0).add(this.rainTl.play(), 0.5);
    };

    renderRain = (e, i) => {
        return (
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                key={e.name}
                ref={e.name}
                strokeMiterlimit="10"
                x1={e.x1}
                y1={e.y1}
                x2={e.x2}
                y2={e.y2}
                style={styles}
            />
        );
    };

    componentDidMount() {
        this.animate();
    }
    render() {
        return (
            <svg
                version="1.1"
                className="Rain"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                xmlSpace="preserve"
            >
                <g ref={this.cloud}>
                    <path
                        fill="#FFFFFF"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeMiterlimit="10"
                        d="M59.869,58.758
                        c5.628,0,10.458-4.335,10.458-9.963c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                        c0-3.782-3.713-9.781-12.552-9.805c-7.738-0.021-14.049,6.44-14.034,14.178c0.002,0.843,0.014,1.554,0.049,2.191
                        c0.214,3.91,2.116,7.6,5.302,9.878c1.192,0.853,2.507,1.43,3.812,1.431"
                    />
                </g>
                <g ref={this.rain}>{rainLines.map(this.renderRain)}</g>
            </svg>
        );
    }
}

export default Rain;