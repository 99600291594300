import React from 'react';

import './Heart.css'

const heart = () => {
    return(
        <svg version="1.1" className="Heart" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
        viewBox="0 0 30 27.21" xmlSpace="preserve">
        <path fill="#FFD302" d="M4.471,14.647l10.531,10.606l10.531-10.606
            c8.191-8.249-2.34-18.855-10.531-10.606C6.805-4.213-3.725,6.393,4.471,14.647z"/>
        </svg>
    )
}

export default heart;