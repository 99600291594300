import React, { Fragment } from "react";

import "./Search.css";

import SearchIcon from "../Icons/Search";

const search = props => {
    return (
        <Fragment>
            <form>
                <div className="InputContainer">
                    <input
                        placeholder="Enter your location..."
                        onChange={props.onchange}
                    ></input>
                    <SearchIcon click={props.onclick} />
                    <button
                        className="Button"
                        type="submit"
                        onClick={props.onclick}
                    >
                        Search
                    </button>
                </div>
            </form>
            {props.children}
        </Fragment>
    );
};

export default search;