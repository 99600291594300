import React from "react";

import "./Header.css";

const header = props => {
    return (
        <header className="Header">
            <h1 className="HeaderTitle">Weather App</h1>
            {props.children}
        </header>
    );
};

export default header;