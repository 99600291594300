import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import "./DayBG.css";

const DayBg = () => {
    let allowMouseMove = false;
    const sunriseTl = gsap.timeline();
    const cloudMove = gsap.timeline({ delay: 2, repeat: -1 });
    
    // Refs for animating the svg
    const cloudsMiddle = useRef();
    const cloudsMiddle2 = useRef();
    const sun = useRef();
    const cloudsRight = useRef();
    const cloudsRight2 = useRef();
    const cloudsRight3 = useRef();
    const cloudsLeft = useRef();
    const cloudsBack = useRef();
    const front = useRef();
    const back = useRef();

    const moveLayers = e => {
        let x = e.clientX;
        let y = e.clientY;
        if (allowMouseMove) {
            gsap.to(
                sun.current,
                0.25,
                { x: x / 120, y: y / 145, ease: "linear" },
                "in"
            );
            gsap.to(
                front.current,
                0.25,
                { x: x / 150, y: -y / 275, ease: "linear" },
                "in"
            );
            gsap.to(
                back.current,
                0.25,
                { x: -x / 125, y: y / 150, ease: "linear" },
                "in"
            );
        }
    };

    const sunrise = () => {
        sunriseTl
            .fromTo(cloudsLeft.current, 1.5, { y: 50 }, { y: 0 }, "in")
            .fromTo(sun.current, 1.5, { y: 50 }, { y: 0 }, "in")
            .fromTo(cloudsRight.current, 1.5, { y: 50 }, { y: 0 }, "in")
            .fromTo(cloudsRight2.current, 1.5, { y: 50 }, { y: 0 }, "in")
            .fromTo(cloudsRight3.current, 1.5, { y: 50 }, { y: 0 }, "in")
            .fromTo(cloudsMiddle.current, 1.5, { y: 50 }, { y: 0 }, "in")
            .fromTo(cloudsMiddle2.current, 1.5, { y: 50 }, { y: 0 }, "in")
            .fromTo(cloudsBack.current, 1.5, { y: 50 }, { y: 0 }, "in");
    };

    const moveClouds = () => {
        cloudMove
            .to(cloudsMiddle.current, 5, { x: 10, ease: "linear" }, "in")
            .to(
                cloudsBack.current,
                4.9,
                { x: 10, y: 10, ease: "linear" },
                "in+=0.1"
            )
            .to(
                cloudsMiddle2.current,
                4.95,
                { x: 10, ease: "linear" },
                "in+=0.05"
            )
            .to(cloudsMiddle.current, 5, { x: 0, ease: "linear" }, "out")
            .to(
                cloudsMiddle2.current,
                4.95,
                { x: 0, ease: "linear" },
                "out+=0.05"
            )
            .to(
                cloudsBack.current,
                4.9,
                { x: 0, y: 0, ease: "linear" },
                "out+=0.1"
            )

            .to(
                cloudsLeft.current,
                5,
                { x: 10, y: 10, ease: "linear" },
                "in"
            )
            .to(
                cloudsLeft.current,
                5,
                { x: 0, y: 0, ease: "linear" },
                "out"
            )
            .to(
                cloudsRight.current,
                5,
                { x: 10, y: 10, ease: "linear" },
                "in"
            )
            .to(
                cloudsRight.current,
                5,
                { x: 0, y: 0, ease: "linear" },
                "out"
            )
            .to(
                cloudsRight2.current,
                5,
                { x: 10, y: 10, ease: "linear" },
                "in"
            )
            .to(
                cloudsRight2.current,
                5,
                { x: 0, y: 0, ease: "linear" },
                "out"
            )

            .to(
                cloudsRight3.current,
                5,
                { x: 10, y: 10, ease: "linear" },
                "in"
            )
            .to(
                cloudsRight3.current,
                5,
                { x: 0, y: 0, ease: "linear" },
                "out"
            );
    };

    useEffect(() => {
        sunrise();
        moveClouds(); 
        setTimeout(() => {
            allowMouseMove = true;
        }, 1000); 
    }, []);

    return (
        <svg
            version="1.1"
            className="DayBG"
            onMouseMove={moveLayers}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1605 1110"
            enableBackground="new 0 0 1605 1110"
            xmlSpace="preserve"
        >
            <defs>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="350%"
                    id="x3"
                    width="300%"
                    x="-1"
                    y="-2"
                >
                    <feGaussianBlur stdDeviation="10.40622"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="250%"
                    id="x2"
                    width="250%"
                    x="-1"
                    y="-2"
                >
                    <feGaussianBlur stdDeviation="10.40622"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="1.33"
                    id="x"
                    width="1.08"
                    x="-.04"
                    y="-.16"
                >
                    <feGaussianBlur stdDeviation="10.40622"></feGaussianBlur>
                </filter>
                <filter colorInterpolationFilters="sRGB" id="w">
                    <feGaussianBlur stdDeviation="10.20534"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    id="v"
                    width="1.05"
                    x="-.03"
                >
                    <feGaussianBlur stdDeviation="10.04744"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="1.25"
                    id="u"
                    width="1.13"
                    x="-.06"
                    y="-.13"
                >
                    <feGaussianBlur stdDeviation="14.56947"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="1.27"
                    id="t"
                    width="1.07"
                    x="-.04"
                    y="-.13"
                >
                    <feGaussianBlur stdDeviation="13.99753"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="1.23"
                    id="s"
                    width="1.09"
                    x="-.04"
                    y="-.11"
                >
                    <feGaussianBlur stdDeviation="17.76938"></feGaussianBlur>
                </filter>
                <filter colorInterpolationFilters="sRGB" id="r">
                    <feGaussianBlur stdDeviation="11.70421"></feGaussianBlur>
                </filter>
                <linearGradient
                    id="skyline_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="3499.8821"
                    y1="-805.4496"
                    x2="3499.8821"
                    y2="-3129.4753"
                    gradientTransform="matrix(-4.2426 0 0 0.4772 15753.8398 1737.6779)"
                >
                    <stop offset="0" stopColor="#FFFFFF" stopOpacity="0.3" />
                    <stop
                        offset="0.0063"
                        stopColor="#FAFEFF"
                        stopOpacity="0.3044"
                    />
                    <stop
                        offset="0.0582"
                        stopColor="#D6F9FF"
                        stopOpacity="0.3407"
                    />
                    <stop
                        offset="0.1162"
                        stopColor="#B6F4FF"
                        stopOpacity="0.3813"
                    />
                    <stop
                        offset="0.1806"
                        stopColor="#9AF0FF"
                        stopOpacity="0.4264"
                    />
                    <stop
                        offset="0.2534"
                        stopColor="#84EDFF"
                        stopOpacity="0.4774"
                    />
                    <stop
                        offset="0.3383"
                        stopColor="#73EAFF"
                        stopOpacity="0.5368"
                    />
                    <stop
                        offset="0.4434"
                        stopColor="#68E8FF"
                        stopOpacity="0.6104"
                    />
                    <stop
                        offset="0.5908"
                        stopColor="#61E7FF"
                        stopOpacity="0.7136"
                    />
                    <stop offset="1" stopColor="#5FE7FF" />
                </linearGradient>
                <radialGradient
                    id="bg_1_"
                    cx="3212.5237"
                    cy="1191.4446"
                    r="803"
                    gradientTransform="matrix(-4.5989 0 0 -0.9366 16863.207 1963.0421)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#417FD9" />
                    <stop offset="0.3186" stopColor="#215AA5" />
                    <stop offset="1" stopColor="#3D83F1" />
                </radialGradient>
                <linearGradient
                    id="SVGID_3_"
                    gradientUnits="userSpaceOnUse"
                    x1="2589.1804"
                    y1="961.9537"
                    x2="2589.1804"
                    y2="981.9637"
                    gradientTransform="matrix(1 0 0 -1 -7.7718 2108.5718)"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="SVGID_4_"
                    cx="2833.2578"
                    cy="1539.302"
                    r="803"
                    gradientTransform="matrix(3.5497 0 0 -0.5099 -9955.7686 1643.4926)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#90B2F2" />
                    <stop offset="0.1602" stopColor="#5A83D4" />
                    <stop offset="0.468" stopColor="#1B589D" />
                    <stop offset="1" stopColor="#133D6D" />
                </radialGradient>
                <radialGradient
                    id="SVGID_5_"
                    cx="286.3003"
                    cy="4477.9639"
                    r="403.86"
                    gradientTransform="matrix(0.9026 -0.0532 -0.0116 -0.1967 916.7028 1713.5264)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_6_"
                    cx="3628.9231"
                    cy="4625.1543"
                    r="377.24"
                    gradientTransform="matrix(-0.6663 -0.0341 0.0133 -0.1634 3484.5442 1713.144)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_7_"
                    cx="1053.851"
                    cy="-292.3139"
                    r="230"
                    fx="1090.051"
                    fy="-184.444"
                    gradientTransform="matrix(0.1729 0.6454 1.432 -0.3837 1645.9041 -46.9341)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_8_"
                    cx="2726.3706"
                    cy="1680.423"
                    r="221.84"
                    gradientTransform="matrix(1.2962 0 0 -0.6956 -2151.491 1914.0264)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_30_"
                    cx="1127.5255"
                    cy="3291.9736"
                    r="410.21"
                    fx="1065.8154"
                    fy="3280.3438"
                    gradientTransform="matrix(3.9588 0.3253 0.0508 -0.2733 -3949.1729 1397.3008)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="back_1_"
                    cx="2938.981"
                    cy="3429.7571"
                    r="807"
                    gradientTransform="matrix(4.6649 0 0 -0.2838 -13824.957 1858.7872)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#618CE6" />
                    <stop offset="0.1231" stopColor="#4270CC" />
                    <stop offset="0.2378" stopColor="#335EB5" />
                    <stop offset="0.5881" stopColor="#153B88" />
                    <stop offset="0.6372" stopColor="#293C80" />
                    <stop offset="0.711" stopColor="#3F3C77" />
                    <stop offset="0.7909" stopColor="#4F3D70" />
                    <stop offset="0.8805" stopColor="#593D6C" />
                    <stop offset="1" stopColor="#5C3D6B" />
                </radialGradient>
                <radialGradient
                    id="SVGID_32_"
                    cx="1522.1846"
                    cy="2573.1938"
                    r="374.01"
                    gradientTransform="matrix(-2.848 0.0946 -0.0272 -0.3762 5512.5513 1720.2001)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_27_"
                    cx="2176.3655"
                    cy="3011.3406"
                    r="284.62"
                    gradientTransform="matrix(-1.5369 2.187378e-05 0 -0.3163 3795.9583 1844.2585)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="front_1_"
                    cx="4087.5383"
                    cy="3280.9556"
                    r="807"
                    gradientTransform="matrix(-4.5102 0 0 -0.3737 18189.373 2052.2183)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#618CE6" />
                    <stop offset="0.1231" stopColor="#4270CC" />
                    <stop offset="0.2378" stopColor="#335EB5" />
                    <stop offset="0.5881" stopColor="#153B88" />
                    <stop offset="1" stopColor="#343A6B" />
                </radialGradient>
                <radialGradient
                    id="lightFront_1_"
                    cx="3442.3538"
                    cy="-1008.4431"
                    r="807"
                    fx="3193.228"
                    fy="-1008.5469"
                    gradientTransform="matrix(1.701474e-11 -0.3282 4.6805 1.819875e-10 6507.9497 2502.0281)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFA26C" stopOpacity="0.6" />
                    <stop
                        offset="0.1802"
                        stopColor="#FEB473"
                        stopOpacity="0.5"
                    />
                    <stop
                        offset="0.4723"
                        stopColor="#FCCA7B"
                        stopOpacity="0.35"
                    />
                    <stop
                        offset="0.751"
                        stopColor="#FBD780"
                        stopOpacity="0.15"
                    />
                    <stop offset="1" stopColor="#FBDC82" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_9_"
                    cx="1082.1993"
                    cy="2865.1677"
                    r="410.21"
                    fx="1020.4893"
                    fy="2853.5378"
                    gradientTransform="matrix(3.9588 0.3253 0.0508 -0.2733 -3949.1729 1397.3008)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_10_"
                    cx="1851.3716"
                    cy="2525.9434"
                    r="374.01"
                    gradientTransform="matrix(-2.848 0.0946 -0.0272 -0.3762 5512.5513 1720.2001)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_11_"
                    cx="2189.3796"
                    cy="2663.5486"
                    r="284.62"
                    gradientTransform="matrix(-1.5369 2.187378e-05 0 -0.3163 3795.9583 1844.2585)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <clipPath id="SVGID_2_">
                    <rect width="1605" height="1110" />
                </clipPath>
                <filter
                    id="Adobe_OpacityMaskFilter"
                    filterUnits="userSpaceOnUse"
                    x="-1.777"
                    y="858.568"
                    width="1605.978"
                    height="345.523"
                >
                    <feColorMatrix
                        type="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                </filter>
                <mask
                    maskUnits="userSpaceOnUse"
                    x="-1.777"
                    y="858.568"
                    width="1605.978"
                    height="345.523"
                    id="p_1_"
                >
                    <path
                        fill="url(#SVGID_3_)"
                        filter="url(#Adobe_OpacityMaskFilter)"
                        d="M1779.709,1126.658h1603.4
                    c0.706,0,1.278,0.572,1.278,1.278l0,0v320.97c0,0.706-0.572,1.278-1.278,1.278h-1603.4c-0.706,0-1.278-0.572-1.278-1.278
                    v-320.95c0-0.706,0.572-1.278,1.278-1.278l0,0"
                    />
                </mask>
            </defs>
            <g clipPath="url(#SVGID_2_)">
                <g transform="translate(-3.8859 -244.09)">
                    <path
                        id="bg"
                        fill="url(#bg_1_)"
                        d="M2417.983,244.076c1.33,0,2.408,0.572,2.408,1.278v1095.4c0,0.007,0,0.015,0,0.022
                        c0,0.706-1.079,1.278-2.409,1.278H-603.852c-1.33,0-2.408-0.572-2.408-1.278v-1095.4l0,0c0-0.706,1.078-1.278,2.408-1.278
                        h3021.835"
                    />
                    <path
                        id="skyline"
                        fill="url(#skyline_1_)"
                        d="M2416.741,244.292H-606.26v1109.025h3023.001V244.292z"
                    />
                    <g ref={sun}>
                        <circle
                            opacity="0.2"
                            fill="#E2DA42"
                            cx="1330"
                            cy="730"
                            r="70"
                        />
                        <circle fill="#E2DA42" cx="1330" cy="730" r="60" />
                    </g>
                    <path
                        mask="url(#p_1_)"
                        fill="url(#SVGID_4_)"
                        d="M-0.477,858.568h1603.4c0.706,0,1.278,0.572,1.278,1.278l0,0v342.967
                        c0.001,0.677-0.525,1.237-1.2,1.278H-0.499c-0.706,0-1.278-0.572-1.278-1.278l0,0V859.846c0-0.706,0.572-1.278,1.278-1.278l0,0"
                    />
                    <g ref={cloudsRight2} opacity="0.5503">
                        <g opacity="0.6243" filter="url(#x3)">
                            <path
                                fill="url(#SVGID_5_)"
                                d="M938.023,869.093c2.273-3.517,3.259-20.051,6.771-27.072c3.206-6.413,17.319,5.79,18.946,6.767
                                c8.978,5.384,14.313,7.346,21.659,0c5.704-5.709,13.701-4.063,21.654-4.063c2.039,0-0.67-18.443,6.771-20.3
                                c9.198-2.302,17.079,2.704,25.717,2.704c12.342,0,9.604,13.012,14.888-8.121c2.254-9.016,5.91-8.92,13.533-10.825
                                c5.164-1.292,17.486,13.423,21.659,17.596c7.063,7.059,10.193,9.47,20.3,9.47c14.998,0,14.457-2.244,27.072,4.063
                                c12.768,6.384,9.389,17.998,24.363,6.767c9.25-6.934,14.668-10.978,21.659-20.3c4.805-6.408,3.738-14.457,8.121-20.305
                                c5.073-6.762,17.127-3.604,24.363-5.412c10.107-2.527,12.184-10.786,12.184-20.305c0-9.217,17.965-6.767,24.363-6.767
                                c6.034,0,17.702,12.289,20.305,14.888c5.484,5.489,8.729,10.739,14.888,18.951c5.929,7.906,8.236,13.237,13.533,20.3
                                c2.417,3.225,16.945,5.901,21.659,12.184c5.738,7.652-1.354,16.242-6.771,21.654c-8.939,8.944-31.087-2.747-41.959,8.126
                                c-8.494,8.494-18.037,10.059-25.717,20.3c-6.188,8.255-11.476,14.854-17.596,23.013c-7.432,9.911-16.745,9.471-28.421,9.471
                                c-21.233,0-49.391-13.701-70.385-18.946c-13.27-3.321-29.44-1.354-43.314-1.354h-47.376c-15.773,0-35.355,2.326-50.08-1.354
                                c-11.485-2.871-17.213-5.355-24.363-14.888c-4.465-5.958-13.964-9.246-20.305-10.83
                                C939.894,872.94,940.229,877.903,938.023,869.093L938.023,869.093z"
                            />
                        </g>
                        <g opacity="0.5132" filter="url(#x3)">
                            <path
                                fill="url(#SVGID_6_)"
                                d="M1303.063,884.712c-11.468-15.385-35.849-8.336-51.401-21.401
                                c-10.357-8.697-0.894-20.211-25.814-20.574c-18.709-0.274-27.102-7.767-40.161,8.528
                                c-17.165,21.424-42.866-4.233-60.32-14.737c-23.533-14.168-17.32,31.103-52.004,26.24c-18.401-2.581-3.475-23.954-21.844-7.81
                                c-15.7,13.799-62.296,1.932-63.303,17.648c-1.546,24.101-46.408,25.261-38.945,38.243
                                c11.461,19.93,314.88-12.211,347.161-15.141C1311.462,894.341,1309.711,893.633,1303.063,884.712L1303.063,884.712z"
                            />
                        </g>
                    </g>
                    <g ref={cloudsRight} opacity="0.5503" filter="url(#u)">
                        <path
                            fill="url(#SVGID_7_)"
                            d="M1205.023,881.647c8.139-18.313,26.452-38.661,48.835-32.557
                            c22.383,6.104,26.452,0,32.557-8.139c6.104-8.139,8.139-26.452,16.278-28.487c8.139-2.035,42.731,2.035,42.731,2.035
                            s-4.07-20.348,2.035-32.557s30.522-16.278,48.835-16.278s38.661-28.487,48.835-34.592c10.174-6.104,26.452-4.07,40.696-4.07
                            s36.626,8.139,44.766-2.035c8.139-10.174,24.418-28.487,32.557-34.592c8.139-6.104,38.661-20.348,42.731-12.209
                            c4.07,8.139,6.104,32.557,14.244,42.731c8.139,10.174,22.383,50.87,22.383,50.87l-2.035,138.366l-463.934-6.104
                            L1205.023,881.647z"
                        />
                    </g>
                    <g ref={cloudsRight3} opacity="0.5503" filter="url(#r)">
                        <path
                            fill="url(#SVGID_8_)"
                            d="M1309.498,876.553c2.877-10.07,15.471-12.422,19.787-25.369
                            c11.897-25.419,41.761-20.456,62.382-14.839c10.07,2.877,28.598,5.682,37.229-1.51s44.595,5.754,46.033-18.701
                            s2.877-48.91,18.701-48.91c15.824,0,27.332-11.508,33.086-27.332c5.754-15.824,18.701-21.578,31.648-11.508
                            c12.947,10.07,27.332,31.648,31.648,43.156c4.316,11.508-4.316,122.276-4.316,122.276l-313.602-1.439
                            C1272.096,892.377,1295.113,879.43,1309.498,876.553z"
                        />
                    </g>
                    <g ref={cloudsBack} filter="url(#s)" opacity="0.6931">
                        <path
                            fill="url(#SVGID_30_)"
                            d="M137.446,834.156c0-98.367,151.636-12.72,184.429,48.025
                            c34.93-49.175,120.82-59.837,154.456-16.46c51.583-0.48,64.439,73.801,100.578,15.843
                            c55.502-54.819,109.503,28.46,121.577,60.12c35.846,2.282,64.818-26.324,88.369,6.639
                            c39.887-28.117,70.544,17.361,115.741,0.832c36.188,32.492,109.503,12.506,112.872,54.536
                            c54.733,6.956,116.291,0.884,116.291,40.932H132.562c-169.046-37.312-170.792-130.893,4.884-210.492V834.156z"
                        />
                    </g>
                    <path
                        ref={back}
                        fill="url(#back_1_)"
                        d="M-606.26,861.348c31.41-9.75,105.014,38,125.642,40s97.513,7,108.764,9
                        s63.758-13,75.01-13s33.754-4,41.255-6c7.501-2,13.127-10.5,31.879-11s22.503,8,43.131,9s50.8,2.45,75.01,7
                        c15.283,2.88,13.052,5.78,20.628,8.41c12.058,4.18,7.501-11.41,24.378-7.41s5.157,8,30.004,3s52.507-22,69.384-23
                        s56.257,11,71.259,12s75.01-14,91.887-14s48.288,4.75,86.261,4s46.881-10,65.634-7s28.598,6.5,46.881,9
                        c18.284,2.5,34.692-2,36.567,4s26.722,8.75,49.694,10s18.752-10,31.879-8c13.127,2,69.384,20,86.261,19s9.376-9,26.253-7
                        s20.628,9,37.505,10s45.006-5,65.634-5c20.628,0,50.632-8,60.008-12s86.261-15,101.263-16s84.386-5,112.515-10
                        s140.25-18.24,164.122-15.41c23.272,2.76,72.16,40.41,109.665,41.41s129.392,4,144.394-2c15.002-6,129.392-21,144.394-14
                        s97.513-8,106.889-9c3.544-0.37,70.022,1.93,79.061,14.05c11.777,15.81,87.63,28.81,146.682,31.45
                        c47.706,2.13,108.577,19.39,220.529,1.36c16.633-2.67,96.95-17.7,150.02-12.6c32.161,3.09,122.435-1.51,146.307,5.02v301.72
                        H-606.26V861.348L-606.26,861.348z"
                    />
                    <g ref={cloudsMiddle} opacity="0.5503" filter="url(#x2)">
                        <path
                            fill="url(#SVGID_32_)"
                            d="M1321.044,928.86c-26.608-19.701-48.964,35.598-56.163-16.33
                            c-41.5,10.617-75.753,96.177-119.886,27.603c-37.116-35.61-91.491-23.763-117.7,0.351c-49.749-0.246-96.37,54.866-138.8,21.937
                            c-47.08,45.688-121.662,76.428-173.114,69.534c-33.468,43.347-100.851-54.058-120.744,15.979
                            c-74.026-23.377-64.086,92.665-111.045,92.454c67.576,13.743,113.28-52.607,177.945-62.37
                            c70.656-24.7,149.634-22.534,218.201,7.433c97.457,16.576,195.941-11.741,294.184-4.858
                            c430.979,74.801,251.85-207.758,147.122-151.511V928.86L1321.044,928.86z"
                        />
                    </g>
                    <g ref={cloudsMiddle2} filter="url(#x2)">
                        <path
                            fill="url(#SVGID_27_)"
                            d="M625.015,980.087c-19.713-40.799-70.482-49.828-109.959-42.505
                                    c-29.799-4.252-58.436-28.591-92.229-15.157c-28.87,8.695-53.315,26.876-83.751,12.093
                                    c-43.551-4.13-89.412,11.453-119.768,44.83c-2.743,28.827,33.098,29.071,52.262,26.031
                                    c35.922-3.947,71.631-13.655,107.933-10.379c74.271-8.695,146.502,25.65,220.613,16.65
                                    c10.716-1.814,21.234-5.113,30.984-10.127"
                        />
                    </g>
                    <path
                        ref={front}
                        fill="url(#front_1_)"
                        d="M2420.392,937.105c-31.411-7.719-105.014,30.088-125.642,31.671
                        s-97.513,5.535-108.764,7.119c-11.251,1.593-63.759-10.285-75.01-10.285s-33.754-3.166-41.255-4.749
                        c-7.501-1.593-13.127-8.319-31.879-8.712c-18.753-0.404-22.503,6.332-43.131,7.119c-20.628,0.797-50.8,1.935-75.01,5.546
                        c-15.283,2.276-13.033,4.573-20.628,6.653c-12.058,3.311-7.501-9.022-24.378-5.856c-16.877,3.166-5.157,6.332-30.004,2.369
                        c-24.847-3.952-52.507-17.414-69.384-18.21c-16.877-0.786-56.257,8.712-71.259,9.498c-15.002,0.797-75.01-11.081-91.887-11.081
                        s-48.288,3.766-86.261,3.166c-37.974-0.59-46.881-7.915-65.634-5.536c-18.752,2.369-28.598,5.142-46.881,7.119
                        c-18.284,1.987-34.692-1.583-36.567,3.166c-1.875,4.759-26.722,6.932-49.694,7.926c-22.972,0.983-18.752-7.926-31.879-6.343
                        s-69.384,15.841-86.261,15.044c-16.877-0.786-9.376-7.119-26.254-5.535c-16.877,1.583-20.628,7.119-37.505,7.915
                        c-16.877,0.786-45.006-3.963-65.634-3.963s-50.632-6.332-60.008-9.498c-9.376-3.166-86.261-11.878-101.263-12.664
                        c-15.002-0.797-84.386-3.963-112.515-7.926c-28.129-3.952-140.25-14.434-164.103-12.199
                        c-23.291,2.183-72.178,32.002-109.683,32.789c-37.505,0.797-129.392,3.166-144.394-1.583
                        c-15.002-4.749-129.392-16.627-144.394-11.081c-15.002,5.535-97.513-6.343-106.889-7.129c-3.544-0.3-70.022,1.521-79.061,11.123
                        c-11.777,12.52-87.63,22.804-146.682,24.894c-47.706,1.687-108.577,15.355-220.529,1.086
                        c-16.633-2.121-96.95-14.02-150.02-9.985c-32.161,2.452-122.435-1.19-146.307,3.983v400.925h3026.652V937.063v0.042H2420.392z"
                    />
                    <path
                        id="lightFront"
                        fill="url(#lightFront_1_)"
                        d="M-606.261,987.207v384.971H2423.45v-363.499
                        c-1020.04-61.9-2053.971,34.13-3029.711-21.435V987.207z"
                    />
                    <g ref={cloudsLeft} opacity="0.6784">
                        <g opacity="0.6931" filter="url(#w)">
                            <path
                                fill="url(#SVGID_9_)"
                                d="M-63.673,936.057c0-98.367,151.636-12.72,184.429,48.025
                                c34.93-49.175,120.82-59.837,154.456-16.46c51.583-0.48,64.439,73.801,100.578,15.843
                                c55.502-54.819,109.503,28.46,121.577,60.12c35.846,2.282,64.818-26.324,88.369,6.639
                                c39.887-28.117,70.544,17.361,115.741,0.832c36.188,32.492,109.503,12.506,112.872,54.536
                                c54.733,6.956,116.291,0.884,116.291,40.932H-68.557c-169.046-37.312-170.792-130.893,4.884-210.492V936.057z"
                            />
                        </g>
                        <g filter="url(#v)">
                            <path
                                fill="url(#SVGID_10_)"
                                d="M384.805,977.777c-26.608-19.701-48.964,35.598-56.163-16.33
                                c-41.5,10.617-75.753,96.177-119.886,27.603c-37.116-35.61-91.491-23.763-117.7,0.351
                                c-49.749-0.246-96.37,54.866-138.8,21.937c-47.08,45.688-121.662,76.428-173.114,69.534
                                c-33.468,43.347-100.851-54.058-120.744,15.979c-74.026-23.377-64.086,92.665-111.045,92.454
                                c67.576,13.743,113.28-52.607,177.945-62.37c70.656-24.7,149.634-22.534,218.201,7.433
                                c97.457,16.576,195.941-11.741,294.184-4.858c430.979,74.801,251.85-207.758,147.122-151.511L384.805,977.777L384.805,977.777
                                z"
                            />
                        </g>
                        <g filter="url(#x)">
                            <path
                                fill="url(#SVGID_11_)"
                                d="M605.014,1090.094c-19.713-40.799-70.482-49.828-109.959-42.505
                                c-29.799-4.252-58.436-28.591-92.229-15.157c-28.87,8.695-53.315,26.876-83.751,12.093
                                c-43.551-4.13-89.412,11.453-119.768,44.83c-2.743,28.827,33.098,29.071,52.262,26.031
                                c35.922-3.947,71.631-13.655,107.933-10.379c74.271-8.695,146.502,25.65,220.613,16.65
                                c10.716-1.814,21.234-5.113,30.984-10.127"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default DayBg;