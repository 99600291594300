import React, { useRef, useEffect } from "react";
import "./Sunny.css";

import gsap from "gsap";

const Sunny = () => {
    const sun = useRef();
    const tl = gsap.timeline();

    useEffect(() => {
        tl.fromTo(
            sun.current,
            0.75,
            {
                y: 30,
                opacity: 0
            },
            { y: 0, opacity: 1, rotation: "+=90", ease: 'power4.in' },
            "in"
        ).to(
            sun.current,
            1.25,
            {
                rotation: "+=180",
                ease: 'back.out(1.7)',
                repeat: -1,
                repeatDelay: 2
            },
            "in+=0.75"
        );

        tl.timeScale(1.25);
    }, []);

    return (
        <svg
            version="1.1"
            className="Sun"
            ref={sun}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
        >
            <circle
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeMiterlimit="10"
                cx="50"
                cy="50"
                r="10"
            />
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="50.081"
                y1="32.176"
                x2="50.081"
                y2="27.988"
            />
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="50.081"
                y1="67.991"
                x2="50.081"
                y2="72.179"
            />
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="62.709"
                y1="37.307"
                x2="65.671"
                y2="34.345"
            />
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="37.386"
                y1="37.373"
                x2="34.424"
                y2="34.412"
            />
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="62.667"
                y1="62.71"
                x2="65.629"
                y2="65.672"
            />
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="37.344"
                y1="62.644"
                x2="34.383"
                y2="65.605"
            />
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="31.966"
                y1="50.009"
                x2="28.026"
                y2="50.009"
            />
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="68.028"
                y1="50.009"
                x2="71.969"
                y2="50.009"
            />
        </svg>
    );
};

export default Sunny;