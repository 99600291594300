import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import "./Overcast.css";

const Overcast = () => {
    const cloud = useRef();

    const animate = () => {
        gsap.fromTo(
            cloud.current,
            1,
            {
                y: 30,
                opacity: 0
            },
            {
                opacity: 1,
                y: 0,
                ease: 'back.out(1, 7)'
            }
        );
    };

    useEffect(() => {
        animate();
    }, []);

    return (
        <svg
            version="1.1"
            className="Cloud"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
        >
            <path
                ref={cloud}
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeMiterlimit="10"
                d="M59.419,63.985c5.628,0,10.486-4.451,10.486-10.078
                c0-3.413-0.867-6.392-3.443-8.241c-4.996-3.411-9.782,0.155-9.782,0.155c0-3.789-3.728-9.805-12.605-9.805
                c-7.722,0-13.981,6.26-13.981,13.981s6.26,13.981,13.981,13.981s13.892-0.004,13.892-0.004L59.419,63.985z"
            />
        </svg>
    );
};

export default Overcast;