import React, { useRef, useEffect } from "react";
import gsap from "gsap";

import "./Lightning.css";

const Lightning = () => {
    const lightning = useRef();
    const cloud = useRef();
    const masterTl = gsap.timeline();
    const cloudTl = gsap.timeline({
        paused: true
    });
    const lightningTl = gsap.timeline({
        paused: true,
        repeat: -1
    });

    const animate = () => {
        gsap.set(lightning.current, {
            opacity: 0,
            transformOrigin: "center top"
        });
        gsap.set(cloud.current, {
            y: 30,
            opacity: 0
        });

        cloudTl.to(cloud.current, 1, {
            y: 0,
            opacity: 1,
            ease: 'back.out(1.7)'
        });

        lightningTl
            .fromTo(
                lightning.current,
                0.6,
                {
                    opacity: 0
                },
                {
                    opacity: 1
                }
            )
            .fromTo(
                lightning.current,
                0.6,
                {
                    opacity: 1,
                    immediateRender: false
                },
                {
                    opacity: 0
                }
            );

        masterTl.add(cloudTl.play(), 0).add(lightningTl.play(), 0.85);
    };

    useEffect(() => {
        animate();
    }, []);

    return (
        <svg
            version="1.1"
            className="Lightning"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
        >
            <g ref={cloud}>
                <path
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeMiterlimit="10"
                    d="M58.998,62.643
                    c5.628,0,10.458-4.335,10.458-9.963c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                    c0-3.782-3.713-9.781-12.552-9.805c-7.738-0.021-14.049,6.44-14.034,14.178c0.002,0.843,0.014,1.554,0.049,2.191
                    c0.214,3.91,2.116,7.6,5.302,9.878c1.192,0.853,2.507,1.43,3.812,1.431"
                />
            </g>
            <polygon
                ref={lightning}
                points="45.221,52.912 55.219,52.904 49.22,62.904 56.219,62.904 43.221,78.902 46.821,66.904 
                40.221,66.904 "
            />
        </svg>
    );
};

export default Lightning;