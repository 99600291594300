import React, { Component } from "react";
import gsap from "gsap";

import "./SnowThunder.css";

const snowDrops = [
    { name: "drop1", x1: "41.5", y1: "52.484", x2: "41.5", y2: "52.155" },
    { name: "drop2", x1: "41.5", y1: "59.485", x2: "41.5", y2: "59.155" },
    { name: "drop3", x1: "41.5", y1: "66.485", x2: "41.5", y2: "66.156" },
    { name: "drop4", x1: "49.501", y1: "55.485", x2: "49.501", y2: "55.155" },
    { name: "drop5", x1: "49.501", y1: "62.485", x2: "49.501", y2: "62.155" },
    { name: "drop6", x1: "49.501", y1: "69.485", x2: "49.501", y2: "69.156" },
    { name: "drop7", x1: "57.291", y1: "52.643", x2: "57.291", y2: "52.314" },
    { name: "drop8", x1: "57.291", y1: "59.644", x2: "57.291", y2: "59.314" },
    { name: "drop9", x1: "57.291", y1: "66.644", x2: "57.291", y2: "66.315" }
];
const styles = {
    opacity: 0
};
class SnowThunder extends Component {
    cloud = React.createRef();
    lightning = React.createRef();

    tl = gsap.timeline({ paused: true });
    lightningTl = gsap.timeline({ paused: true, repeat: -1 });
    masterTl = gsap.timeline();

    renderDrops = el => {
        return (
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                style={styles}
                ref={el.name}
                key={el.name}
                x1={el.x1}
                y1={el.y1}
                x2={el.x2}
                y2={el.y2}
            />
        );
    };
    animate = () => {
        this.tl.fromTo(
            this.cloud.current,
            1,
            { y: 30 },
            { y: 0, ease: 'back.out(1.7)' },
            "in"
        );

        this.lightningTl
            .fromTo(this.lightning.current, 0.6, { opacity: 0 }, { opacity: 1 })
            .fromTo(
                this.lightning.current,
                0.6,
                { opacity: 1, immediateRender: false },
                { opacity: 0 }
            );
        this.masterTl.add(this.tl.play(), 0).add(this.lightningTl.play(), 1);

        snowDrops.forEach((el, i) => {
            this.tl.to(
                this.refs[el.name],
                2.25,
                {
                    opacity: 1,
                    bezier: [
                        { x: 0.5, y: 7 },
                        { x: -1, y: 10 },
                        { x: 1.5, y: 13 },
                        { x: 0, y: 15 }
                    ],
                    repeat: -1,
                    ease: 'sine.in'
                },
                0.5 * i
            );
        });
    };
    componentDidMount() {
        this.animate();
    }
    render() {
        return (
            <svg
                version="1.1"
                className="SnowThunder"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                xmlSpace="preserve"
            >
                <path
                    ref={this.cloud}
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeMiterlimit="10"
                    d="M59.869,58.217
                    c5.628,0,10.458-4.335,10.458-9.963c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                    c0-3.782-3.713-9.781-12.552-9.805c-7.738-0.021-14.049,6.44-14.034,14.178c0.002,0.843,0.014,1.554,0.049,2.191
                    c0.214,3.91,2.116,7.6,5.302,9.878c1.192,0.853,2.507,1.43,3.812,1.431"
                />
                <polygon
                    ref={this.lightning}
                    points="46.092,48.511 56.09,48.503 50.091,58.503 57.09,58.503 44.092,74.501 47.692,62.503 41.092,62.503"
                />
                {snowDrops.map(this.renderDrops)}
            </svg>
        );
    }
}

export default SnowThunder;