import React, { Component } from "react";
import gsap from "gsap";

import "./Wind.css";

const windPaths = [
    {
        name: "gust1",
        d: `M29.989,50.047
    c0,0-0.392-3.954,4.282-4.008h21.462c1.197,0,2.413-0.324,3.259-1.172c0.586-0.588,1.205-1.509,1.205-2.63
    c-0.023-1.703-0.759-4.162-4.355-4.162`
    },
    {
        name: "gust2",
        d: `M29.989,50.077
    c0,0-0.392,3.954,4.282,4.008h21.462c1.197,0,2.413,0.324,3.259,1.172c0.586,0.588,1.205,1.509,1.205,2.63
    c-0.023,1.703-0.759,4.162-4.355,4.162`
    }
];
const styles = {
    strokeDashoffset: "42px",
    strokeDasharray: "42px"
};
const stylesSmallLine = {
    strokeDashoffset: "20px",
    strokeDasharray: "20px"
};

class Wind extends Component {
    wind = React.createRef();

    renderGust = el => {
        return (
            <path
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                ref={el.name}
                key={el.name}
                d={el.d}
                style={styles}
            />
        );
    };

    animate = () => {
        this.tl = gsap.timeline({ repeat: -1, repeatDelay: 1, delay: 0.25 });
        windPaths.forEach((el, i) => {
            this.tl
                .to(
                    this.refs[el.name],
                    0.5,
                    {
                        strokeDasharray: "42px",
                        strokeDashoffset: "0px",
                        ease: "power2.out"
                    },
                    "blow"
                )
                .to(
                    this.wind.current,
                    0.5,
                    {
                        strokeDasharray: "20px",
                        strokeDashoffset: "0px",
                        ease: "power2.out"
                    },
                    "blow+=0.15"
                );
        });
        this.tl.timeScale(0.95);
    };

    componentDidMount() {
        this.animate();
    }

    render() {
        return (
            <svg
                version="1.1"
                className="Wind"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                xmlSpace="preserve"
            >
                {windPaths.map(this.renderGust)}
                <path
                    ref={this.wind}
                    style={stylesSmallLine}
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    d="M63.138,50.05h2.461
            c1.197,0,2.413-0.324,3.259-1.172c0.586-0.588,1.205-1.509,1.205-2.63c-0.023-1.703-0.759-4.162-4.355-4.162"
                />
            </svg>
        );
    }
}

export default Wind;