import React, { Component } from "react";
import gsap from "gsap";

import "./Pellets.css";
const pellets = [
    { name: "pellet1", cx: "41.582", cy: "52.434", r: "1.737" },
    { name: "pellet2", cx: "49.458", cy: "55.317", r: "1.737" },
    { name: "pellet3", cx: "57.333", cy: "52.434", r: "1.737" },
    { name: "pellet4", cx: "41.582", cy: "52.434", r: "1.737" },
    { name: "pellet5", cx: "49.458", cy: "55.317", r: "1.737" },
    { name: "pellet6", cx: "57.333", cy: "52.434", r: "1.737" },
    { name: "pellet7", cx: "41.582", cy: "52.434", r: "1.737" },
    { name: "pellet8", cx: "49.458", cy: "55.317", r: "1.737" },
    { name: "pellet9", cx: "57.333", cy: "52.434", r: "1.737" }
];
const styles = {
    opacity: "0"
};
class Pellets extends Component {
    cloud = React.createRef();
    tl = gsap.timeline();

    renderPellets = el => {
        return (
            <circle
                ref={el.name}
                key={el.name}
                cx={el.cx}
                cy={el.cy}
                r={el.r}
                style={styles}
            />
        );
    };

    animate = () => {
        this.tl.fromTo(
            this.cloud.current,
            1,
            { y: 30 },
            { y: 0, ease: 'back.out(1.7)' },
            "in"
        );
        pellets.forEach((el, i) => {
            this.tl
                .set(this.refs[el.name], { opacity: 1, delay: 0.1 })
                .to(
                    this.refs[el.name],
                    0.45,
                    { ease: 'expo.in', y: 15, repeat: -1, delay: 0.25 },
                    0.3 * i
                );
        });
    };

    componentDidMount() {
        this.animate();
    }

    render() {
        return (
            <svg
                version="1.1"
                className="Pellets"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                xmlSpace="preserve"
            >
                {pellets.map(this.renderPellets)}
                <path
                    ref={this.cloud}
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeMiterlimit="10"
                    d="M59.869,58.217
                c5.628,0,10.458-4.335,10.458-9.963c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                c0-3.782-3.713-9.781-12.552-9.805c-7.738-0.021-14.049,6.44-14.034,14.178c0.002,0.843,0.014,1.554,0.049,2.191
                c0.214,3.91,2.116,7.6,5.302,9.878c1.192,0.853,2.507,1.43,3.812,1.431"
                />
            </svg>
        );
    }
}

export default Pellets;