import React, { useRef, useEffect } from "react";
import gsap from "gsap";

import "./PartlyCloudy.css";

const PartlyCloudy = () => {
    const moon = useRef();
    const cloud = useRef();
    const tl = gsap.timeline();

    const animate = () => {
        gsap.set(moon.current, {
            y: 30,
            opacity: 0,
            rotation: 90
        });
        gsap.set(cloud.current, {
            y: 30,
            opacity: 1
        });

        tl.to(
            moon.current,
            1,
            {
                y: 0,
                opacity: 1,
                rotation: 0,
                ease: 'back.out(1, 7)'
            },
            "in"
        ).to(
            cloud.current,
            1,
            {
                y: 0,
                opacity: 1,
                ease: 'back.out(1, 7)'
            },
            "in"
        );
    };

    useEffect(() => {
        animate();
    }, []);

    return (
        <svg
            version="1.1"
            className="PartlyCloudy"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
        >
            <g>
                <path
                    ref={moon}
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeMiterlimit="10"
                    d="M51.367,42.367c0.141,5.109,4.401,9.368,9.51,9.51
                c5.534,0.153,10.069-4.288,10.069-9.787c0,0-1.373-0.111-1.373-0.111c-4.387-0.354-7.883-3.814-8.283-8.198l-0.135-1.483
                C55.654,32.298,51.214,36.833,51.367,42.367z"
                />
                <path
                    ref={cloud}
                    fill="#FFFDFD"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeMiterlimit="10"
                    d="M58.379,67.697
                c5.628,0,11.329-4.592,11.329-10.219c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                c0-3.789-3.728-9.805-12.605-9.805c-7.722,0-13.981,6.26-13.981,13.981s6.26,13.981,13.981,13.981s13.892-0.004,13.892-0.004
                L58.379,67.697z"
                />
            </g>
        </svg>
    );
};

export default PartlyCloudy;