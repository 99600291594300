import React, { Component } from "react";
import gsap from "gsap";

import "./PatchyRain.css";

const rainLines = [
    { name: "line1", x1: "40.652", x2: "40.652", y1: "56.581", y2: "69.828" },
    { name: "line2", x1: "48.363", x2: "48.363", y1: "59.581", y2: "72.828" },
    { name: "line3", x1: "56.363", x2: "56.363", y1: "56.581", y2: "69.828" }
];
// Styling the lines
const styles = {
    strokeDashoffset: "40px",
    strokeDasharray: "40px"
};
class PatchyRain extends Component {
    moon = React.createRef();
    cloud = React.createRef();
    tl = gsap.timeline({
        paused: true
    });
    rainTl = gsap.timeline({
        paused: true
    });
    masterTl = gsap.timeline();

    renderRain = e => {
        return (
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                key={e.name}
                ref={e.name}
                x1={e.x1}
                y1={e.y1}
                x2={e.x2}
                y2={e.y2}
                style={styles}
            />
        );
    };
    animate = () => {
        this.tl
            .fromTo(
                this.moon.current,
                1,
                {
                    rotation: 90,
                    y: 30,
                    opacity: 0
                },
                {
                    y: 0,
                    rotation: 0,
                    opacity: 1,
                    ease: 'back.out(1.7)'
                },
                "in"
            )
            .fromTo(
                this.cloud.current,
                1,
                {
                    y: 30
                },
                {
                    y: 0,
                    ease: 'back.out(1.7)'
                },
                "in"
            );

        rainLines.forEach((e, i) => {
            this.rainTl.to(
                this.refs[e.name],
                1,
                {
                    "stroke-dashoffset": "0px",
                    "stroke-dasharray": "40px",
                    repeat: -1,
                    ease: 'expo.in'
                },
                0.1 * i
            );
        });
        this.masterTl.add(this.tl.play(), 0).add(this.rainTl.play(), 0.5);
    };
    componentDidMount() {
        this.animate();
    }
    render() {
        return (
            <svg
                version="1.1"
                className="PatchyRain"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                xmlSpace="preserve"
            >
                <g ref={this.moon}>
                    <path
                        fill="none"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeMiterlimit="10"
                        d="M51.619,37.24c0.141,5.109,4.401,9.368,9.51,9.51
                        c5.534,0.153,10.069-4.288,10.069-9.787c0,0-1.373-0.111-1.373-0.111c-4.387-0.354-7.883-3.814-8.283-8.198l-0.135-1.483
                        C55.906,27.172,51.466,31.706,51.619,37.24z"
                    />
                </g>
                <g ref={this.cloud}>
                    <path
                        fill="#FFFFFF"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeMiterlimit="10"
                        d="M58.998,62.643
                        c5.628,0,10.458-4.335,10.458-9.963c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                        c0-3.782-3.713-9.781-12.552-9.805c-7.738-0.021-14.049,6.44-14.034,14.178c0.002,0.843,0.014,1.554,0.049,2.191
                        c0.214,3.91,2.116,7.6,5.302,9.878c1.192,0.853,2.507,1.43,3.812,1.431"
                    />
                </g>
                <g>{rainLines.map(this.renderRain)}</g>
            </svg>
        );
    }
}

export default PatchyRain;