import React from "react";

import "./Footer.css";

import Heart from "../Icons/Heart";

const footer = () => {
    return (
        <footer className="Footer">
            Made with <Heart /> by{" "}
            <a className="footerLink" href="https://kasperdebruyne.be">
                Kasper De Bruyne
            </a>
        </footer>
    );
};

export default footer;