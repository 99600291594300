import React, { useRef, useEffect } from "react";
import gsap from "gsap";

import "./FreezingFog.css";

const FreezingFog = () => {
    const mist = useRef();
    const cloud = useRef();
    const freeze = useRef();
    const tl = gsap.timeline();

    const animate = () => {
        gsap.set(cloud.current, {
            y: 30
        });
        gsap.set(freeze.current, {
            y: 30
        });
        gsap.set(mist.current, {
            rotation: 180,
            transformOrigin: "center center",
            "stroke-dashoffset": "40px",
            "stroke-dasharray": "40px"
        });

        tl.fromTo(
            cloud.current,
            1,
            {
                y: 30,
                opacity: 0
            },
            {
                opacity: 1,
                y: 0,
                ease: "back.out(1, 7)"
            },
            "in"
        )
            .fromTo(
                freeze.current,
                1,
                {
                    y: 30,
                    opacity: 0
                },
                {
                    opacity: 1,
                    y: 0,
                    ease: "back.out(1, 7)"
                },
                "in"
            )
            .to(
                mist.current,
                0.5,
                {
                    "stroke-dasharray": "40px",
                    "stroke-dashoffset": "0px"
                },
                "in+=0.5"
            );
    };

    useEffect(() => {
        animate();
    }, []);

    return (
        <svg
            version="1.1"
            className="FreezingFog"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 100 100"
            xmlSpace="preserve"
        >
            <g ref={cloud}>
                <path
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeMiterlimit="10"
                    d="M59.869,59.41
                        c5.628,0,10.458-4.335,10.458-9.963c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                        c0-3.782-3.713-9.781-12.552-9.805c-7.738-0.021-14.049,6.44-14.034,14.178c0.002,0.843,0.014,1.554,0.049,2.191
                        c0.214,3.91,2.116,7.6,5.302,9.878c1.192,0.853,2.507,1.43,3.812,1.431"
                />
            </g>
            <path
                ref={freeze}
                d="M41.942,53.407c0.553-0.957,1.775-1.283,2.732-0.732l2.196,1.267v-2.535c0-1.104,0.896-2,2-2s2,0.896,2,2
                    v2.535l2.195-1.268c0.957-0.551,2.18-0.225,2.731,0.732c0.553,0.957,0.225,2.18-0.731,2.732l-2.196,1.268l2.196,1.268
                    c0.956,0.553,1.284,1.775,0.731,2.732c-0.552,0.955-1.774,1.283-2.731,0.73L50.87,60.87v2.535c0,1.105-0.896,2-2,2s-2-0.895-2-2
                    V60.87l-2.195,1.268c-0.957,0.553-2.18,0.225-2.732-0.73c-0.552-0.957-0.225-2.18,0.732-2.732l2.195-1.269l-2.195-1.268
                    C41.718,55.586,41.391,54.364,41.942,53.407z M48.87,59.407c1.104,0,2-0.896,2-2c0-1.105-0.896-2-2-2s-2,0.895-2,2
                    C46.87,58.51,47.766,59.407,48.87,59.407z"
            />

            <line
                ref={mist}
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="30.961"
                y1="68.291"
                x2="69.039"
                y2="68.291"
            />
        </svg>
    );
};

export default FreezingFog;