import React, { Fragment } from "react";
import "./Weather.css";

// Weather Card Icons
import Wind from "./UI/Icons/Wind";
// import Compass from './UI/Icons/Compass';

// Weather Condition Icons
import Sunny from "./UI/Icons/Conditions/Day/Sunny";
import Overcast from "./UI/Icons/Conditions/Overcast";
import PartlyCloudy from "./UI/Icons/Conditions/Day/PartlyCloudy";
import PartlyCloudyNight from "./UI/Icons/Conditions/Night/PartlyCloudy";
import ClearNight from "./UI/Icons/Conditions/Night/Clear";
import Cloudy from "./UI/Icons/Conditions/Night/Cloudy";
import Mist from "./UI/Icons/Conditions/Mist";
import PatchyRainNight from "./UI/Icons/Conditions/Night/PatchyRain";
import PatchyRain from "./UI/Icons/Conditions/Day/PatchyRain";
import PatchySnow from "./UI/Icons/Conditions/PatchySnow";
import PatchySleet from "./UI/Icons/Conditions/PatchySleet";
import Lightning from "./UI/Icons/Conditions/Lightning";
import BlowingSnow from "./UI/Icons/Conditions/BlowingSnow";
import FreezingFog from "./UI/Icons/Conditions/FreezingFog";
import Rain from "./UI/Icons/Conditions/Rain";
import FreezingRain from "./UI/Icons/Conditions/FreezingRain";
import Pellets from "./UI/Icons/Conditions/Pellets";
import RainThunder from "./UI/Icons/Conditions/RainThunder";
import SnowThunder from "./UI/Icons/Conditions/SnowThunder";

const Weather = props => {
    let icon = null;
    if (props.isDay === 'yes') {
        if (props.code === 113) {
            icon = <Sunny />;
        }
        if (
            props.code === 176 ||
            props.code === 293 ||
            props.code === 296 ||
            props.code === 299
        ) {
            icon = <PatchyRain />;
        }
        if (props.code === 116) {
            icon = <PartlyCloudy />;
        }    
    }
    if (props.isDay === "no") {
        if (props.code === 113) {
            icon = <ClearNight />;
        }
        if (
            props.code === 296 ||
            props.code === 176 ||
            props.code === 293 ||
            props.code === 299
        ) {
            icon = <PatchyRainNight />;
        }
        if (props.code === 116) {
            icon = <PartlyCloudyNight />;
        }
    }   
    if (props.code === 119) {
        icon = <Cloudy />
    }
    if (props.code === 122) {
        icon = <Overcast />;
    }
    if (props.code === 143 || props.code === 248) {
        icon = <Mist />;
    }
    if (props.code === 200) {
        icon = <Lightning />;
    }
    if (props.code === 227 || props.code === 230) {
        icon = <BlowingSnow />;
    }
    if (props.code === 260) {
        icon = <FreezingFog />;
    }
    if (props.code === 302 ||
        props.code === 305 ||
        props.code === 308 || 
        props.code === 353) {
        icon = <Rain />;
    }
    if (props.code === 284
        || props.code === 263 
        || props.code === 266
        || props.code === 281
        || props.code === 311) {
        icon = <FreezingRain />;
    }
  
    if (props.code === 182 ||
        props.code === 185
    ) {
        icon = <PatchySleet />;
    }
    
    if (props.code === 326 || props.code === 179) {
        icon = <PatchySnow />;
    }
    if (props.condition === "Light showers of ice pellets" 
        || props.condition === "Moderate or heavy showers of ice pellets") {
        icon = <Pellets />;
    }
    if (props.condition === "Patchy light rain with thunder"
        || props.condition === "Moderate or heavy rain with thunder") {
        icon = <RainThunder />;
    }
    if (props.condition === "Patchy light snow with thunder"
        || props.condition === "Moderate or heavy snow with thunder") {
        icon = <SnowThunder />;
    }
    return (
        <Fragment>
            {props.location !== "" ? (
                <div className="WeatherCard" ref={props.forwardRef}>
                    <h2 className="Location">{props.location}</h2>

                    {icon}

                    <p className="Condition">{props.condition}</p>
                    <p className="Temperature">
                        {props.temperature}
                        <span>{props.unit}</span>
                    </p>
                    <p className="Feel">
                        Feels like {props.temperatureFeel} {props.unit}
                    </p>
                    {/* <p className="WindDirectionIcon">
                        <Compass/>
                    </p>
                    <p className="WindDirectionText">
                        {props.wind}
                    </p> */}
                    <p className="WindSpeedIcon">
                        <Wind />
                    </p>
                    <p className="WindSpeedText">
                        {props.windSpeed} {props.speedUnit}
                    </p>

                    {props.children}
                </div>
            ) : null}
        </Fragment>
    );
};

export default Weather;