import React, { Component } from "react";
import gsap from "gsap";

import "./PatchySnow.css";

const snowDrops = [
    { name: "drop1", x1: "41.5", y1: "52.483", x2: "41.5", y2: "52.154" },
    { name: "drop2", x1: "41.5", y1: "59.484", x2: "41.5", y2: "59.154" },
    { name: "drop3", x1: "41.5", y1: "66.484", x2: "41.5", y2: "66.155" },
    { name: "drop4", x1: "49.501", y1: "55.484", x2: "49.501", y2: "55.154" },
    { name: "drop5", x1: "49.501", y1: "62.484", x2: "49.501", y2: "62.154" },
    { name: "drop6", x1: "49.501", y1: "69.484", x2: "49.501", y2: "69.155" },
    { name: "drop7", x1: "57.291", y1: "52.642", x2: "57.291", y2: "52.313" },
    { name: "drop8", x1: "57.291", y1: "59.643", x2: "57.291", y2: "59.313" },
    { name: "drop9", x1: "57.291", y1: "66.643", x2: "57.291", y2: "66.314" }
];

const styles = {
    opacity: "0"
};

class PatchySnow extends Component {
    cloud = React.createRef();
    snow = React.createRef();
    tl = gsap.timeline();

    animate = () => {
        this.tl.fromTo(
            this.cloud.current,
            1,
            {
                y: 30
            },
            {
                y: 0,
                ease: 'back.out(1.7)'
            },
            "in"
        );

        snowDrops.forEach((e, i) => {
            this.tl.to(
                this.refs[e.name],
                2.25,
                {
                    opacity: 1,
                    bezier: [
                        { x: 0.5, y: 7 },
                        { x: -1, y: 10 },
                        { x: 1.5, y: 13 },
                        { x: 0, y: 15 }
                    ],
                    repeat: -1,
                    ease: 'sine.in'
                },
                0.5 * i
            );
        });
    };

    renderSnow = (e, i) => {
        return (
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                key={e.name}
                ref={e.name}
                x1={e.x1}
                y1={e.y1}
                x2={e.x2}
                y2={e.y2}
                style={styles}
            />
        );
    };

    componentDidMount() {
        this.animate();
    }

    render() {
        return (
            <svg
                version="1.1"
                className="PatchySnow"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                xmlSpace="preserve"
            >
                <g ref={this.cloud}>
                    <path
                        fill="#FFFFFF"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeMiterlimit="10"
                        d="M59.869,58.216
                        c5.628,0,10.458-4.335,10.458-9.963c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                        c0-3.782-3.713-9.781-12.552-9.805c-7.738-0.021-14.049,6.44-14.034,14.178c0.002,0.843,0.014,1.554,0.049,2.191
                        c0.214,3.91,2.116,7.6,5.302,9.878c1.192,0.853,2.507,1.43,3.812,1.431"
                    />
                </g>
                <g ref={this.snow}>{snowDrops.map(this.renderSnow)}</g>
            </svg>
        );
    }
}

export default PatchySnow;