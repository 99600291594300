import React, { Component } from "react";
import gsap from "gsap";

import "./RainThunder.css";

const rainLines = [
    { name: "line1", x1: "41.5", y1: "52.786", x2: "41.5", y2: "66.034" },
    { name: "line2", x1: "49.21", y1: "55.786", x2: "49.21", y2: "69.034" },
    { name: "line3", x1: "57.211", y1: "52.786", x2: "57.211", y2: "66.034" }
];
const styles = {
    strokeDashoffset: "40px",
    strokeDasharray: "40px"
};
class RainThunder extends Component {
    lightning = React.createRef();
    cloud = React.createRef();
    tl = gsap.timeline({ paused: true });
    lightningTl = gsap.timeline({ paused: true, repeat: -1 });
    masterTl = gsap.timeline();

    renderRain = el => {
        return (
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                ref={el.name}
                key={el.name}
                x1={el.x1}
                y1={el.y1}
                x2={el.x2}
                y2={el.y2}
                style={styles}
            />
        );
    };

    animate = () => {
        this.tl.fromTo(
            this.cloud.current,
            1,
            { y: 30 },
            { y: 0, ease: 'back.out(1.7)' },
            "in"
        );
        this.lightningTl
            .fromTo(
                this.lightning.current,
                0.6,
                { opacity: 0 },
                { opacity: 1 },
                "in"
            )
            .fromTo(
                this.lightning.current,
                0.6,
                { opacity: 1, immediateRender: false },
                { opacity: 0 }
            );

        rainLines.forEach((el, i) => {
            this.lightningTl
                .to(
                    this.refs[el.name],
                    1,
                    {
                        "stroke-dashoffset": "0px",
                        "stroke-dasharray": "40px",
                        ease: 'expo.in',
                        delay: 0.6
                    },
                    0.1 * i
                )
                .to(this.refs[el.name], 0.1, { opacity: 0, ease: 'expo.in' });
        });

        this.masterTl.add(this.tl.play()).add(this.lightningTl.play(), 1);
    };

    componentDidMount() {
        this.animate();
    }

    render() {
        return (
            <svg
                version="1.1"
                className="RainThunder"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                xmlSpace="preserve"
            >
                <path
                    ref={this.cloud}
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeMiterlimit="10"
                    d="M59.869,58.668
                c5.628,0,10.458-4.335,10.458-9.963c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                c0-3.782-3.713-9.781-12.552-9.805c-7.738-0.021-14.049,6.44-14.034,14.178c0.002,0.843,0.014,1.554,0.049,2.191
                c0.214,3.91,2.116,7.6,5.302,9.878c1.192,0.853,2.507,1.43,3.812,1.431"
                />
                {rainLines.map(this.renderRain)}
                <polygon
                    ref={this.lightning}
                    points="47.001,48.99 56.999,48.982 51,58.982 57.999,58.982 45.001,74.98 48.601,62.982 42.001,62.982 "
                />
            </svg>
        );
    }
}

export default RainThunder;
