import React, { Component } from "react";
import gsap from "gsap";

import "./PatchyRain.css";

const rainLines = [
    { name: "line1", x1: "39.402", x2: "39.402", y1: "59.709", y2: "72.956" },
    { name: "line2", x1: "47.112", x2: "47.112", y1: "62.709", y2: "75.956" },
    { name: "line3", x1: "55.113", x2: "55.113", y1: "59.709", y2: "72.956" }
];

// Styling the lines
const styles = {
    strokeDashoffset: "40px",
    strokeDasharray: "40px"
};
class PatchyRain extends Component {
    sun = React.createRef();
    cloud = React.createRef();
    tl = gsap.timeline({
        paused: true
    });
    rainTl = gsap.timeline({
        paused: true
    });
    masterTl = gsap.timeline();

    renderRain = e => {
        return (
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                key={e.name}
                ref={e.name}
                x1={e.x1}
                y1={e.y1}
                x2={e.x2}
                y2={e.y2}
                style={styles}
            />
        );
    };
    animate = () => {
        this.tl
            .fromTo(
                this.sun.current,
                1,
                {
                    opacity: 0,
                    y: 30
                },
                {
                    transformOrigin: "center center",
                    rotation: 90,
                    opacity: 1,
                    y: 0,
                    ease: "back.out(1.7)"
                },
                "in"
            )
            .fromTo(
                this.cloud.current,
                1,
                {
                    y: 30
                },
                {
                    y: 0,
                    ease: "back.out(1.7)"
                },
                "in"
            );

        rainLines.forEach((e, i) => {
            this.rainTl.to(
                this.refs[e.name],
                1,
                {
                    "stroke-dashoffset": "0px",
                    "stroke-dasharray": "40px",
                    repeat: -1,
                    ease: "expo.in"
                },
                0.1 * i
            );
        });
        this.masterTl.add(this.tl.play(), 0).add(this.rainTl.play(), 0.5);
    };
    componentDidMount() {
        this.animate();
    }

    render() {
        return (
            <svg
                version="1.1"
                className="PatchyRain"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                xmlSpace="preserve"
            >
                <g ref={this.sun}>
                    <circle
                        fill="none"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeMiterlimit="10"
                        cx="52.69"
                        cy="43.858"
                        r="8.993"
                    />
                    <line
                        fill="none"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        x1="52.78"
                        y1="27.807"
                        x2="52.78"
                        y2="24.044"
                    />
                    <line
                        fill="none"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        x1="52.78"
                        y1="59.987"
                        x2="52.78"
                        y2="63.75"
                    />
                    <line
                        fill="none"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        x1="64.127"
                        y1="32.417"
                        x2="66.788"
                        y2="29.756"
                    />
                    <line
                        fill="none"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        x1="41.374"
                        y1="32.477"
                        x2="38.713"
                        y2="29.816"
                    />
                    <line
                        fill="none"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        x1="64.09"
                        y1="55.242"
                        x2="66.751"
                        y2="57.903"
                    />
                    <line
                        fill="none"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        x1="36.504"
                        y1="43.83"
                        x2="32.964"
                        y2="43.83"
                    />
                    <line
                        fill="none"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        x1="68.907"
                        y1="43.83"
                        x2="72.447"
                        y2="43.83"
                    />
                </g>
                <g ref={this.cloud}>
                    <path
                        fill="#FFFFFF"
                        stroke="#000000"
                        strokeWidth="4"
                        strokeMiterlimit="10"
                        d="M57.748,65.771
                c5.628,0,10.458-4.335,10.458-9.963c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                c0-3.782-3.713-9.781-12.552-9.805c-7.738-0.021-14.049,6.44-14.034,14.178c0.002,0.843,0.014,1.554,0.049,2.191
                c0.214,3.91,2.116,7.6,5.302,9.878c1.192,0.853,2.507,1.43,3.812,1.431"
                    />
                </g>
                <g>{rainLines.map(this.renderRain)}</g>
            </svg>
        );
    }
}

export default PatchyRain;