import React, { Component } from "react";
import gsap from "gsap";
import "./FreezingRain.css";

const freezeLines = [
    { name: "line1", x1: "41.5", y1: "52.876", x2: "41.5", y2: "66.124" },
    { name: "line2", x1: "49.21", y1: "55.876", x2: "49.21", y2: "68.123" },
    { name: "line3", x1: "57.211", y1: "52.876", x2: "57.211", y2: "66.124" }
];
const styles = {
    strokeDashoffset: "40px",
    strokeDasharray: "40px"
};

const freezePaths = [
    {
        name: "fPath1",
        d: `M37.291,63.75c0.324-0.56,1.039-0.751,1.599-0.429l1.286,0.742V62.58c0-0.646,0.525-1.171,1.171-1.171
    c0.646,0,1.171,0.525,1.171,1.171v1.484l1.285-0.742c0.56-0.323,1.276-0.132,1.599,0.429c0.324,0.56,0.132,1.276-0.428,1.599
    l-1.286,0.742l1.286,0.742c0.56,0.324,0.752,1.039,0.428,1.599c-0.323,0.559-1.038,0.751-1.599,0.427l-1.285-0.741v1.484
    c0,0.647-0.525,1.171-1.171,1.171c-0.646,0-1.171-0.524-1.171-1.171v-1.484l-1.285,0.742c-0.56,0.324-1.276,0.132-1.599-0.427
    c-0.323-0.56-0.132-1.276,0.429-1.599l1.285-0.743L37.72,65.35C37.16,65.026,36.969,64.311,37.291,63.75z M41.347,67.263
    c0.646,0,1.171-0.525,1.171-1.171c0-0.647-0.525-1.171-1.171-1.171c-0.646,0-1.171,0.524-1.171,1.171
    C40.176,66.738,40.7,67.263,41.347,67.263z`
    },
    {
        name: "fPath2",
        d: `M45.14,65.467c0.324-0.56,1.039-0.751,1.599-0.429l1.286,0.742v-1.484c0-0.646,0.525-1.171,1.171-1.171
    c0.646,0,1.171,0.525,1.171,1.171v1.484l1.285-0.742c0.56-0.323,1.276-0.132,1.599,0.429c0.324,0.56,0.132,1.276-0.428,1.599
    l-1.286,0.742l1.286,0.742c0.56,0.324,0.752,1.039,0.428,1.599c-0.323,0.559-1.038,0.751-1.599,0.427l-1.285-0.741v1.484
    c0,0.647-0.525,1.171-1.171,1.171c-0.646,0-1.171-0.524-1.171-1.171v-1.484l-1.285,0.742c-0.56,0.324-1.276,0.132-1.599-0.427
    c-0.323-0.56-0.132-1.276,0.429-1.599l1.285-0.743l-1.285-0.742C45.009,66.742,44.818,66.027,45.14,65.467z M49.196,68.979
    c0.646,0,1.171-0.525,1.171-1.171c0-0.647-0.525-1.171-1.171-1.171c-0.646,0-1.171,0.524-1.171,1.171
    C48.025,68.454,48.55,68.979,49.196,68.979z`
    },
    {
        name: "fPath3",
        d: `M53.135,63.618c0.324-0.56,1.039-0.751,1.599-0.429l1.286,0.742v-1.484c0-0.646,0.525-1.171,1.171-1.171
    c0.646,0,1.171,0.525,1.171,1.171v1.484l1.285-0.742c0.56-0.323,1.276-0.132,1.599,0.429c0.324,0.56,0.132,1.276-0.428,1.599
    l-1.286,0.742l1.286,0.742c0.56,0.324,0.752,1.039,0.428,1.599c-0.323,0.559-1.038,0.751-1.599,0.427l-1.285-0.741v1.484
    c0,0.647-0.525,1.171-1.171,1.171c-0.646,0-1.171-0.524-1.171-1.171v-1.484l-1.285,0.742c-0.56,0.324-1.276,0.132-1.599-0.427
    c-0.323-0.56-0.132-1.276,0.429-1.599l1.285-0.743l-1.285-0.742C53.004,64.894,52.813,64.179,53.135,63.618z M57.191,67.131
    c0.646,0,1.171-0.525,1.171-1.171c0-0.647-0.525-1.171-1.171-1.171c-0.646,0-1.171,0.524-1.171,1.171
    C56.02,66.606,56.545,67.131,57.191,67.131z`
    }
];
class FreezingRain extends Component {
    cloud = React.createRef();
    tl = gsap.timeline();

    renderLines = el => {
        return (
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                key={el.name}
                ref={el.name}
                x1={el.x1}
                y1={el.y1}
                x2={el.x2}
                y2={el.y2}
                style={styles}
            />
        );
    };
    renderPaths = el => {
        return <path key={el.name} ref={el.name} d={el.d} />;
    };
    animate = () => {
        this.tl.fromTo(
            this.cloud.current,
            1,
            {
                y: 30
            },
            {
                y: 0,
                ease: 'back.out(1.7)'
            },
            "in"
        );

        freezeLines.forEach((el, i) => {
            this.tl.to(
                this.refs[el.name],
                1,
                {
                    "stroke-dashoffset": "0px",
                    "stroke-dasharray": "40px",
                    repeat: -1,
                    ease: 'expo.in',
                    delay: 0.5
                },
                0.1 * i
            );
        });

        freezePaths.forEach((el, i) => {
            this.tl.fromTo(
                this.refs[el.name],
                1,
                {
                    opacity: 0,
                    scale: 0,
                    transformOrigin: "center center"
                },
                {
                    scale: 1,
                    opacity: 1,
                    repeat: -1,
                    ease: "expo.in",
                    delay: 0.5
                },
                0.1 * i
            );
        });
    };

    componentDidMount() {
        this.animate();
    }

    render() {
        return (
            <svg
                version="1.1"
                className="FreezingRain"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                xmlSpace="preserve"
            >
                <path
                    ref={this.cloud}
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeMiterlimit="10"
                    d="M59.869,58.758
                c5.628,0,10.458-4.335,10.458-9.963c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                c0-3.782-3.713-9.781-12.552-9.805c-7.738-0.021-14.049,6.44-14.034,14.178c0.002,0.843,0.014,1.554,0.049,2.191
                c0.214,3.91,2.116,7.6,5.302,9.878c1.192,0.853,2.507,1.43,3.812,1.431"
                />
                {freezeLines.map(this.renderLines)}
                {freezePaths.map(this.renderPaths)}
            </svg>
        );
    }
}

export default FreezingRain;