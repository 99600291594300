import React from 'react';
import './Search.css'

const search = (props) => {
    return (
        <svg version="1.1" onClick={props.click} className="Search" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 18.5 18.5" enableBackground="new 0 0 18.5 18.5" xmlSpace="preserve">
        <g id="Group_18" transform="translate(-21.812 -120.087)">
        <path fill="none" stroke="#000000" strokeWidth="3" strokeLinecap="round" d="M38.812,137.087l-4.444-4.262
            l-6.056-6.238"/>
        <circle fill="none" stroke="#000000" strokeWidth="3" strokeLinecap="round" cx="30.312" cy="128.587" r="7"/>
        </g>
        <circle fill="#EEE" stroke="#EEE" cx="8.5" cy="8.5" r="5"/>
        </svg>
   
    )
}


export default search;