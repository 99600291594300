import React from "react";
import "./NightBG.css";

const nightBG = () => {
    return (
        <svg
            version="1.1"
            className="NightBG"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1604.153 1109.804"
            enableBackground="new 0 0 1604.153 1109.804"
            xmlSpace="preserve"
        >
            <defs>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="350%"
                    id="x3"
                    width="300%"
                    x="-1"
                    y="-2"
                >
                    <feGaussianBlur stdDeviation="10.40622"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="250%"
                    id="x2"
                    width="250%"
                    x="-1"
                    y="-2"
                >
                    <feGaussianBlur stdDeviation="10.40622"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="1.33"
                    id="x"
                    width="1.08"
                    x="-.04"
                    y="-.16"
                >
                    <feGaussianBlur stdDeviation="10.40622"></feGaussianBlur>
                </filter>
                <filter colorInterpolationFilters="sRGB" id="w">
                    <feGaussianBlur stdDeviation="10.20534"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    id="v"
                    width="1.05"
                    x="-.03"
                >
                    <feGaussianBlur stdDeviation="10.04744"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="1.25"
                    id="u"
                    width="1.13"
                    x="-.06"
                    y="-.13"
                >
                    <feGaussianBlur stdDeviation="14.56947"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="1.27"
                    id="t"
                    width="1.07"
                    x="-.04"
                    y="-.13"
                >
                    <feGaussianBlur stdDeviation="13.99753"></feGaussianBlur>
                </filter>
                <filter
                    colorInterpolationFilters="sRGB"
                    height="1.23"
                    id="s"
                    width="1.09"
                    x="-.04"
                    y="-.11"
                >
                    <feGaussianBlur stdDeviation="17.76938"></feGaussianBlur>
                </filter>
                <filter colorInterpolationFilters="sRGB" id="r">
                    <feGaussianBlur stdDeviation="11.70421"></feGaussianBlur>
                </filter>
                <radialGradient
                    id="bg_1_"
                    cx="2857.032"
                    cy="948.4223"
                    r="803"
                    gradientTransform="matrix(4.5989 0 0 -0.9366 -13414.0898 1490.3373)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#417FD9" />
                    <stop offset="0.3186" stopColor="#215AA5" />
                    <stop offset="1" stopColor="#002A7D" />
                </radialGradient>
                <radialGradient
                    id="SVGID_3_"
                    cx="248.1083"
                    cy="-36.4887"
                    r="125.3297"
                    gradientTransform="matrix(1 0 0 1 -7.7718 510)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#DDE0D3" />
                    <stop
                        offset="0.9629"
                        stopColor="#DDE0D3"
                        stopOpacity="0.2296"
                    />
                    <stop offset="1" stopColor="#DDE0D3" stopOpacity="0.2" />
                </radialGradient>
                <radialGradient
                    id="skyline_1_"
                    cx="2813.2849"
                    cy="-2493.7107"
                    r="803"
                    gradientTransform="matrix(4.2426 0 0 0.882 -12309.7598 3238.2761)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#BAB5CC" />
                    <stop offset="0.3038" stopColor="#9FA2B3" />
                    <stop
                        offset="0.5191"
                        stopColor="#8E98B1"
                        stopOpacity="0.6907"
                    />
                    <stop
                        offset="0.9451"
                        stopColor="#617FAE"
                        stopOpacity="0.0788"
                    />
                    <stop offset="1" stopColor="#5B7BAD" stopOpacity="0" />
                </radialGradient>
                <linearGradient
                    id="SVGID_4_"
                    gradientUnits="userSpaceOnUse"
                    x1="2589.2959"
                    y1="718.8638"
                    x2="2589.2959"
                    y2="738.8738"
                    gradientTransform="matrix(1 0 0 -1 -7.7718 1620.3918)"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </linearGradient>
                <radialGradient
                    id="SVGID_5_"
                    cx="2830.499"
                    cy="1297.1733"
                    r="803"
                    gradientTransform="matrix(3.5497 0 0 -0.5099 -9945.8604 1274.941)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#90B2F2" />
                    <stop offset="0.1602" stopColor="#5A83D4" />
                    <stop offset="0.468" stopColor="#1B589D" />
                    <stop offset="1" stopColor="#133D6D" />
                </radialGradient>
                <radialGradient
                    id="SVGID_6_"
                    cx="286.8972"
                    cy="4237.7456"
                    r="403.86"
                    gradientTransform="matrix(0.9026 -0.0532 -0.0116 -0.1967 913.4929 1421.2172)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_7_"
                    cx="3619.1787"
                    cy="4388.4072"
                    r="377.24"
                    gradientTransform="matrix(-0.6663 -0.0341 0.0133 -0.1634 3481.3157 1429.0372)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_8_"
                    cx="1050.0692"
                    cy="-533.6223"
                    r="230"
                    fx="1086.2693"
                    fy="-425.7524"
                    gradientTransform="matrix(0.1729 0.6454 1.432 -0.3837 1992.2269 -382.1735)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_30_"
                    cx="1122.0934"
                    cy="2991.1052"
                    r="410.21"
                    fx="1060.3833"
                    fy="2979.4753"
                    gradientTransform="matrix(3.9588 0.3253 0.0508 -0.2733 -3925.2756 1087.765)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="back_1_"
                    cx="2935.9529"
                    cy="3189.1909"
                    r="807"
                    gradientTransform="matrix(4.6649 0 0 -0.2838 -13810.7158 1545.4244)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#618CE6" />
                    <stop offset="0.1231" stopColor="#4270CC" />
                    <stop offset="0.2378" stopColor="#335EB5" />
                    <stop offset="0.5881" stopColor="#153B88" />
                    <stop offset="1" stopColor="#04215C" />
                </radialGradient>
                <radialGradient
                    id="SVGID_9_"
                    cx="1525.3365"
                    cy="2290.9626"
                    r="374.01"
                    gradientTransform="matrix(-2.848 0.0946 -0.0272 -0.3762 5490.9595 1384.651)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_10_"
                    cx="2138.373"
                    cy="2731.8513"
                    r="284.62"
                    gradientTransform="matrix(-1.5369 2.187378e-05 0 -0.3163 3786.1001 1522.9629)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="front_1_"
                    cx="4082.7651"
                    cy="3039.5417"
                    r="807"
                    gradientTransform="matrix(-4.5102 0 0 -0.3737 18167.9609 1716.9119)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#618CE6" />
                    <stop offset="0.1231" stopColor="#4270CC" />
                    <stop offset="0.2378" stopColor="#335EB5" />
                    <stop offset="0.5881" stopColor="#153B88" />
                    <stop offset="1" stopColor="#04215C" />
                </radialGradient>
                <radialGradient
                    id="SVGID_11_"
                    cx="2725.5718"
                    cy="1437.7708"
                    r="221.84"
                    gradientTransform="matrix(1.2962 0 0 -0.6956 -2150.3401 1500.1473)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="lightFront_1_"
                    cx="3334.7346"
                    cy="-808.5579"
                    r="807"
                    gradientTransform="matrix(0 -0.379 -2.9781 0 -2378.5742 2391.9634)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#174C99" />
                    <stop offset="1" stopColor="#174C99" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_12_"
                    cx="1079.2627"
                    cy="2625.8669"
                    r="410.21"
                    fx="1017.5527"
                    fy="2614.2371"
                    gradientTransform="matrix(3.9588 0.3253 0.0508 -0.2733 -3925.2756 1087.765)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_13_"
                    cx="1846.0588"
                    cy="2284.1528"
                    r="374.01"
                    gradientTransform="matrix(-2.848 0.0946 -0.0272 -0.3762 5490.9595 1384.651)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <radialGradient
                    id="SVGID_14_"
                    cx="2182.8901"
                    cy="2422.6201"
                    r="284.62"
                    gradientTransform="matrix(-1.5369 2.187378e-05 0 -0.3163 3786.1001 1522.9629)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#FFFFFF" />
                    <stop offset="1" stopColor="#FFFFFF" stopOpacity="0" />
                </radialGradient>
                <clipPath id="SVGID_2_">
                    <rect x="0" y="0" width="1605" height="1110" />
                </clipPath>
                <filter
                    id="Adobe_OpacityMaskFilter"
                    filterUnits="userSpaceOnUse"
                    x="-1.662"
                    y="613.478"
                    width="1605.978"
                    height="345.523"
                >
                    <feColorMatrix
                        type="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                </filter>
            </defs>
            <g clipPath="url(#SVGID_2_)">
                <path
                    id="bg"
                    fill="url(#bg_1_)"
                    d="M-603.737-0.992h3021.835c1.33,0,2.408,0.572,2.408,1.278l0,0
                v1095.4c0,0.706-1.078,1.278-2.408,1.278H-603.737c-1.33,0-2.408-0.572-2.409-1.278c0-0.007,0-0.015,0-0.022V0.264
                c0-0.706,1.078-1.278,2.408-1.278"
                />
                <g>
                    <circle fill="#DDE0D3" cx="240.336" cy="510" r="60" />
                    <circle
                        opacity="0.2"
                        fill="url(#SVGID_3_)"
                        cx="240.336"
                        cy="510"
                        r="70"
                    />
                </g>
                <path
                    id="skyline"
                    fill="url(#skyline_1_)"
                    d="M-606.145,1109.96h3023.001V-3.333H-606.145V1109.96z"
                />
                <mask
                    maskUnits="userSpaceOnUse"
                    x="-1.662"
                    y="613.478"
                    width="1605.978"
                    height="345.523"
                    id="p_1_"
                >
                    <path
                        fill="url(#SVGID_4_)"
                        filter="url(#Adobe_OpacityMaskFilter)"
                        d="M1779.824,881.568h1603.4
                    c0.706,0,1.278,0.572,1.278,1.278l0,0v320.97c0,0.706-0.572,1.278-1.278,1.278h-1603.4c-0.706,0-1.278-0.572-1.278-1.278v-320.95
                    c0-0.706,0.572-1.278,1.278-1.278l0,0"
                    />
                </mask>
                <path
                    mask="url(#p_1_)"
                    fill="url(#SVGID_5_)"
                    d="M-0.362,613.478h1603.4
                c0.706,0,1.278,0.572,1.278,1.278l0,0v342.967c0.001,0.677-0.525,1.237-1.2,1.278H-0.384c-0.706,0-1.278-0.572-1.278-1.278l0,0
                V614.756c0-0.706,0.572-1.278,1.278-1.278l0,0"
                />

                <g opacity="0.5503" filter="url(#x)">
                    <path
                        fill="url(#SVGID_8_)"
                        d="M1205.138,636.557c8.139-18.313,26.452-38.661,48.835-32.557c22.383,6.104,26.452,0,32.557-8.139
                    c6.104-8.139,8.139-26.452,16.278-28.487c8.139-2.035,42.731,2.035,42.731,2.035s-4.07-20.348,2.035-32.557
                    s30.522-16.278,48.835-16.278s38.661-28.487,48.835-34.592c10.174-6.104,26.452-4.07,40.696-4.07s36.626,8.139,44.766-2.035
                    c8.139-10.174,24.418-28.487,32.557-34.592c8.139-6.104,38.661-20.348,42.731-12.209c4.07,8.139,6.104,32.557,14.244,42.731
                    c8.139,10.174,22.383,50.87,22.383,50.87l-2.035,138.366l-463.934-6.104L1205.138,636.557z"
                    />
                </g>
                <g opacity="0.5503">
                    <g opacity="0.6243" filter="url(#x3)">
                        <path
                            fill="url(#SVGID_6_)"
                            d="M938.138,624.003c2.273-3.517,3.259-20.051,6.771-27.072c3.206-6.413,17.319,5.79,18.946,6.767
                        c8.978,5.384,14.313,7.346,21.659,0c5.704-5.709,13.701-4.063,21.654-4.063c2.039,0-0.67-18.443,6.771-20.3
                        c9.198-2.302,17.079,2.704,25.717,2.704c12.342,0,9.604,13.012,14.888-8.121c2.254-9.016,5.91-8.92,13.533-10.825
                        c5.164-1.292,17.486,13.423,21.659,17.596c7.063,7.059,10.193,9.47,20.3,9.47c14.998,0,14.457-2.244,27.072,4.063
                        c12.768,6.384,9.389,17.998,24.363,6.767c9.25-6.934,14.668-10.978,21.659-20.3c4.805-6.408,3.738-14.457,8.121-20.305
                        c5.073-6.762,17.127-3.604,24.363-5.412c10.107-2.527,12.184-10.786,12.184-20.305c0-9.217,17.965-6.767,24.363-6.767
                        c6.034,0,17.702,12.289,20.305,14.888c5.484,5.489,8.729,10.739,14.888,18.951c5.929,7.906,8.236,13.237,13.533,20.3
                        c2.417,3.225,16.945,5.901,21.659,12.184c5.738,7.652-1.354,16.242-6.771,21.654c-8.939,8.944-31.087-2.747-41.959,8.126
                        c-8.494,8.494-18.037,10.059-25.717,20.3c-6.188,8.255-11.476,14.854-17.596,23.013c-7.432,9.911-16.745,9.471-28.421,9.471
                        c-21.233,0-49.391-13.701-70.385-18.946c-13.27-3.321-29.44-1.354-43.314-1.354h-47.376c-15.773,0-35.355,2.326-50.08-1.354
                        c-11.485-2.871-17.213-5.355-24.363-14.888c-4.465-5.958-13.964-9.246-20.305-10.83
                        C940.009,627.85,940.344,632.813,938.138,624.003L938.138,624.003z"
                        />
                    </g>
                    <g opacity="0.5132" filter="url(#x3)">
                        <path
                            fill="url(#SVGID_7_)"
                            d="M1303.178,639.622c-11.468-15.385-35.849-8.336-51.401-21.401
                        c-10.357-8.697-0.894-20.211-25.814-20.574c-18.709-0.274-27.102-7.767-40.161,8.528c-17.165,21.424-42.866-4.233-60.32-14.737
                        c-23.533-14.168-17.32,31.103-52.004,26.24c-18.401-2.581-3.475-23.954-21.844-7.81c-15.7,13.799-62.296,1.932-63.303,17.648
                        c-1.546,24.101-46.408,25.261-38.945,38.243c11.461,19.93,314.88-12.211,347.161-15.141
                        C1311.577,649.251,1309.826,648.543,1303.178,639.622L1303.178,639.622z"
                        />
                    </g>
                </g>
                <g opacity="0.5503" filter="url(#r)">
                    <path
                        fill="url(#SVGID_11_)"
                        d="M1309.613,631.463c2.877-10.07,15.471-12.422,19.787-25.369
                    c11.897-25.419,41.761-20.456,62.382-14.839c10.07,2.877,28.598,5.682,37.229-1.51s44.595,5.754,46.033-18.701
                    s2.877-48.91,18.701-48.91c15.824,0,27.332-11.508,33.086-27.332c5.754-15.824,18.701-21.578,31.648-11.508
                    c12.947,10.07,27.332,31.648,31.648,43.156c4.316,11.508-4.316,122.276-4.316,122.276l-313.602-1.439
                    C1272.211,647.287,1295.228,634.34,1309.613,631.463z"
                    />
                </g>
                <g opacity="0.6931" filter="url(#s)">
                    <path
                        fill="url(#SVGID_30_)"
                        d="M124.554,605.08c0-98.367,151.636-12.72,184.429,48.025c34.93-49.175,120.82-59.837,154.456-16.46
                c51.583-0.48,64.439,73.801,100.578,15.843c55.502-54.819,109.503,28.46,121.577,60.12c35.846,2.282,64.818-26.324,88.369,6.639
                c39.887-28.117,70.544,17.361,115.741,0.832c36.188,32.492,109.503,12.506,112.872,54.536
                c54.733,6.956,116.291,0.884,116.291,40.932H119.67c-169.046-37.312-170.792-130.893,4.884-210.492V605.08z"
                    />
                </g>
                <path
                    fill="url(#back_1_)"
                    d="M-606.145,616.258c31.41-9.75,105.014,38,125.642,40
                s97.513,7,108.764,9s63.758-13,75.01-13s33.754-4,41.255-6s13.127-10.5,31.879-11s22.503,8,43.131,9s50.8,2.45,75.01,7
                c15.283,2.88,13.052,5.78,20.628,8.41c12.058,4.18,7.501-11.41,24.378-7.41s5.157,8,30.004,3s52.507-22,69.384-23
                s56.257,11,71.259,12s75.01-14,91.887-14s48.288,4.75,86.261,4s46.881-10,65.634-7s28.598,6.5,46.881,9
                c18.284,2.5,34.692-2,36.567,4s26.722,8.75,49.694,10s18.752-10,31.879-8c13.127,2,69.384,20,86.261,19s9.376-9,26.253-7
                s20.628,9,37.505,10s45.006-5,65.634-5c20.628,0,50.632-8,60.008-12s86.261-15,101.263-16s84.386-5,112.515-10
                c28.129-5,140.25-18.24,164.122-15.41c23.272,2.76,72.16,40.41,109.665,41.41s129.392,4,144.394-2c15.002-6,129.392-21,144.394-14
                s97.513-8,106.889-9c3.544-0.37,70.022,1.93,79.061,14.05c11.777,15.81,87.63,28.81,146.682,31.45
                c47.706,2.13,108.577,19.39,220.529,1.36c16.633-2.67,96.95-17.7,150.02-12.6c32.161,3.09,122.435-1.51,146.307,5.02v301.72
                H-606.145V616.258L-606.145,616.258z"
                />
                <g filter="url(#t)">
                    <path
                        fill="url(#SVGID_9_)"
                        d="M1298.152,699.785c-26.608-19.701-48.964,35.598-56.163-16.33
                    c-41.5,10.617-75.753,96.177-119.886,27.603c-37.116-35.61-91.491-23.763-117.7,0.351c-49.749-0.246-96.37,54.866-138.8,21.937
                    c-47.08,45.688-121.662,76.428-173.114,69.534c-33.468,43.347-100.851-54.058-120.744,15.979
                    c-74.026-23.377-64.086,92.665-111.045,92.454c67.576,13.743,113.28-52.607,177.945-62.37
                    c70.656-24.7,149.634-22.534,218.201,7.433c97.457,16.576,195.941-11.741,294.184-4.858
                    c430.979,74.801,251.85-207.758,147.122-151.511V699.785L1298.152,699.785z"
                    />
                </g>
                <g filter="url(#x2)">
                    <path
                        fill="url(#SVGID_10_)"
                        d="M673.547,747.193c-19.713-40.799-70.482-49.828-109.959-42.505
                    c-29.799-4.252-58.436-28.591-92.229-15.157c-28.87,8.695-53.315,26.876-83.751,12.093c-43.551-4.13-89.412,11.453-119.768,44.83
                    c-2.743,28.827,33.098,29.071,52.262,26.031c35.922-3.947,71.631-13.655,107.933-10.379c74.271-8.695,146.502,25.65,220.613,16.65
                    c10.716-1.814,21.234-5.113,30.984-10.127"
                    />
                </g>
                <path
                    fill="url(#front_1_)"
                    d="M2420.507,692.015
                c-31.411-7.719-105.014,30.088-125.642,31.671s-97.513,5.535-108.764,7.119c-11.251,1.593-63.759-10.285-75.01-10.285
                s-33.754-3.166-41.255-4.749c-7.501-1.593-13.127-8.319-31.879-8.712c-18.753-0.404-22.503,6.332-43.131,7.119
                c-20.628,0.797-50.8,1.935-75.01,5.546c-15.283,2.276-13.033,4.573-20.628,6.653c-12.058,3.311-7.501-9.022-24.378-5.856
                c-16.877,3.166-5.157,6.332-30.004,2.369c-24.847-3.952-52.507-17.414-69.384-18.21c-16.877-0.786-56.257,8.712-71.259,9.498
                c-15.002,0.797-75.01-11.081-91.887-11.081s-48.288,3.766-86.261,3.166c-37.974-0.59-46.881-7.915-65.634-5.536
                c-18.752,2.369-28.598,5.142-46.881,7.119c-18.284,1.987-34.692-1.583-36.567,3.166c-1.875,4.759-26.722,6.932-49.694,7.926
                c-22.972,0.983-18.752-7.926-31.879-6.343s-69.384,15.841-86.261,15.044c-16.877-0.786-9.376-7.119-26.254-5.535
                c-16.877,1.583-20.628,7.119-37.505,7.915c-16.877,0.786-45.006-3.963-65.634-3.963s-50.632-6.332-60.008-9.498
                s-86.261-11.878-101.263-12.664c-15.002-0.797-84.386-3.963-112.515-7.926c-28.129-3.952-140.25-14.434-164.103-12.199
                c-23.291,2.183-72.178,32.002-109.683,32.789c-37.505,0.797-129.392,3.166-144.394-1.583
                c-15.002-4.749-129.392-16.627-144.394-11.081c-15.002,5.535-97.513-6.343-106.889-7.129c-3.544-0.3-70.022,1.521-79.061,11.123
                c-11.777,12.52-87.63,22.804-146.682,24.894c-47.706,1.687-108.577,15.355-220.529,1.086c-16.633-2.121-96.95-14.02-150.02-9.985
                c-32.161,2.452-122.435-1.19-146.307,3.983v400.925H2420.51V691.973v0.042H2420.507z"
                />
                <path
                    id="lightFront"
                    fill="url(#lightFront_1_)"
                    d="M2423.564,683.608
                c-975.739,64.161-2009.67-46.724-3029.711,24.751v419.729h3029.711V683.566V683.608z"
                />
                <g opacity="0.6784">
                    <g opacity="0.6931" filter="url(#w)">
                        <path
                            fill="url(#SVGID_12_)"
                            d="M-63.558,690.967c0-98.367,151.636-12.72,184.429,48.025
                        c34.93-49.175,120.82-59.837,154.456-16.46c51.583-0.48,64.439,73.801,100.578,15.843c55.502-54.819,109.503,28.46,121.577,60.12
                        c35.846,2.282,64.818-26.324,88.369,6.639c39.887-28.117,70.544,17.361,115.741,0.832
                        c36.188,32.492,109.503,12.506,112.872,54.536c54.733,6.956,116.291,0.884,116.291,40.932H-68.442
                        c-169.046-37.312-170.792-130.893,4.884-210.492V690.967z"
                        />
                    </g>
                    <g filter="url(#v)">
                        <path
                            fill="url(#SVGID_13_)"
                            d="M384.92,732.687c-26.608-19.701-48.964,35.598-56.163-16.33
                        c-41.5,10.617-75.753,96.177-119.886,27.603c-37.116-35.61-91.491-23.763-117.7,0.351c-49.749-0.246-96.37,54.866-138.8,21.937
                        c-47.08,45.688-121.662,76.428-173.114,69.534c-33.468,43.347-100.851-54.058-120.744,15.979
                        c-74.026-23.377-64.086,92.665-111.045,92.454c67.576,13.743,113.28-52.607,177.945-62.37
                        c70.656-24.7,149.634-22.534,218.201,7.433c97.457,16.576,195.941-11.741,294.184-4.858
                        c430.979,74.801,251.85-207.758,147.122-151.511L384.92,732.687L384.92,732.687z"
                        />
                    </g>
                    <g filter="url(#x)">
                        <path
                            fill="url(#SVGID_14_)"
                            d="M605.129,845.004c-19.713-40.799-70.482-49.828-109.959-42.505
                        c-29.799-4.252-58.436-28.591-92.229-15.157c-28.87,8.695-53.315,26.876-83.751,12.093c-43.551-4.13-89.412,11.453-119.768,44.83
                        c-2.743,28.827,33.098,29.071,52.262,26.031c35.922-3.947,71.631-13.655,107.933-10.379
                        c74.271-8.695,146.502,25.65,220.613,16.65c10.716-1.814,21.234-5.113,30.984-10.127"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default nightBG;