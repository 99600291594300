import React, { Component } from "react";
import gsap from "gsap";

import "./BlowingSnow.css";

const snowDrops = [
    { name: "drop1", x1: "39.402", y1: "60.038", x2: "39.402", y2: "59.709" },
    { name: "drop2", x1: "39.402", y1: "67.039", x2: "39.402", y2: "66.709" },
    { name: "drop3", x1: "39.402", y1: "74.039", x2: "39.402", y2: "73.709" },
    { name: "drop4", x1: "47.403", y1: "63.038", x2: "47.403", y2: "62.709" },
    { name: "drop5", x1: "47.403", y1: "70.039", x2: "47.403", y2: "69.709" }
];

const styles = {
    opacity: "0"
};

class BlowingSnow extends Component {
    cloud = React.createRef();
    wind = React.createRef();
    wind2 = React.createRef();

    tl = gsap.timeline();

    renderSnow = (e, i) => {
        return (
            <line
                fill="none"
                stroke="#000000"
                strokeWidth="4"
                strokeLinecap="round"
                strokeMiterlimit="10"
                key={e.name}
                ref={e.name}
                x1={e.x1}
                y1={e.y1}
                x2={e.x2}
                y2={e.y2}
                style={styles}
            />
        );
    };

    animate = () => {
        gsap.set(this.wind.current, {
            "stroke-dashoffset": "30px",
            "stroke-dasharray": "30px"
        });
        gsap.set(this.wind2.current, {
            "stroke-dashoffset": "30px",
            "stroke-dasharray": "30px"
        });

        this.tl
            .fromTo(
                this.cloud.current,
                1,
                {
                    y: 30
                },
                {
                    y: 0,
                    ease: "back.out(1, 7)"
                },
                "in"
            )
            .to(
                this.wind.current,
                1,
                {
                    "stroke-dashoffset": "0px",
                    "stroke-dasharray": "30px",
                    repeat: -1,
                    ease: "expo.in"
                },
                0.1,
                "in+=0.5"
            )
            .to(
                this.wind2.current,
                1,
                {
                    "stroke-dashoffset": "0px",
                    "stroke-dasharray": "30px",
                    repeat: -1,
                    ease: "expo.in"
                },
                0.1,
                "in+=0.5"
            );

        snowDrops.forEach((e, i) => {
            this.tl.to(
                this.refs[e.name],
                2.25,
                {
                    opacity: 1,
                    bezier: [
                        { x: 0.5, y: 7 },
                        { x: -1, y: 10 },
                        { x: 1.5, y: 13 },
                        { x: 0, y: 15 }
                    ],
                    repeat: -1,
                    ease: 'sine.in'
                },
                0.5 * i
            );
        });
    };

    componentDidMount() {
        this.animate();
    }

    render() {
        return (
            <svg
                version="1.1"
                className="BlowingSnow"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 100 100"
                xmlSpace="preserve"
            >
                <path
                    ref={this.cloud}
                    fill="#FFFFFF"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeMiterlimit="10"
                    d="M59.869,58.216
                    c5.628,0,10.458-4.335,10.458-9.963c0-3.413-1.71-6.238-4.286-8.088c-4.996-3.411-9.782,0.155-9.782,0.155
                    c0-3.782-3.713-9.781-12.552-9.805c-7.738-0.021-14.049,6.44-14.034,14.178c0.002,0.843,0.014,1.554,0.049,2.191
                    c0.214,3.91,2.116,7.6,5.302,9.878c1.192,0.853,2.507,1.43,3.812,1.431"
                />
                {snowDrops.map(this.renderSnow)}
                <path
                    ref={this.wind}
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    d="M76.257,67.432H63.285
                    c-3.163,0-3.373-4.788-0.214-4.945c0.181-0.009,0.372-0.012,0.571-0.008"
                />
                <path
                    ref={this.wind2}
                    fill="none"
                    stroke="#000000"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    d="M76.257,67.477H63.285
                    c-3.163,0-3.373,4.788-0.214,4.945c0.181,0.009,0.372,0.012,0.571,0.008"
                />
            </svg>
        );
    }
}

export default BlowingSnow;